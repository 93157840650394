import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type {
    Channel,
    ChannelExpanded,
    Message,
    PaginatedChannelList,
    SearchMessagesResponse,
    UnreadCount,
} from "@/types/api/data-contracts";

class ChatService {
    // eslint-disable-next-line no-use-before-define
    private static instance: ChatService;

    public static getInstance(): ChatService {
        if (!ChatService.instance) {
            ChatService.instance = new ChatService();
        }
        return ChatService.instance;
    }

    getInquiryChannelDetailsForSupplier(
        inquiryId: string
    ): AxiosPromise<ChannelExpanded> {
        const url = `/inquiries/supplier/${inquiryId}/channel/`;
        return axiosInstance.get(url);
    }

    getBrandChannelId(inquiryId: string, quoteId: string) {
        return axiosInstance.get(
            `inquiries/${inquiryId}/quotes/brand/${quoteId}/channel/`
        );
    }

    getSupplierChannelId(inquiryId: string, quoteId: string) {
        return axiosInstance.get(
            `inquiries/${inquiryId}/quotes/supplier/${quoteId}/channel/`
        );
    }

    getChannelDetails(channelId: string): AxiosPromise<Channel> {
        const url = `/channels/chats/${channelId}/`;
        return axiosInstance.get(url);
    }

    sendTextMessage(channelId: string, text: string) {
        return axiosInstance.post(`channels/${channelId}/messages/`, {
            text,
        });
    }

    sendAttachmentMessage(
        channelId: string,
        payload: object
    ): AxiosPromise<Message> {
        return axiosInstance.post(`channels/${channelId}/messages/`, payload);
    }

    getMessages(channelId: string | null, page: number) {
        return axiosInstance.get(`channels/${channelId}/messages/`, {
            params: { page },
        });
    }

    getUserChatList(page: number): AxiosPromise<PaginatedChannelList> {
        const url = "/channels/chats/";
        return axiosInstance.get(url, { params: { page } });
    }

    markChannelLatestMessageAsRead(
        channelId: string
    ): AxiosPromise<UnreadCount> {
        const url = `/channels/chats/${channelId}/mark-read/`;
        return axiosInstance.post(url);
    }

    /**
     * Request to search messages by text, search will match:
     * - Message body
     * - Message title
     * - Message author
     * - Message attachment
     * @param {string} text
     * @param {number} page
     */
    searchMessages(
        text: string,
        page?: number
    ): AxiosPromise<{
        results: SearchMessagesResponse[];
        next: string | null;
    }> {
        const pageParam = page ? `?page=${page}` : "";
        const url = `/channels/chats/search-messages/${pageParam}`;
        return axiosInstance.post(url, { text });
    }
}

export default ChatService;
