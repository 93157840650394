import type { ObjectDirective, App } from "vue";
// import * as process from "process";

type AppDirective = {
    name: string;
    hook: ObjectDirective;
};

/**
 * Add a data-testid attribute to elements only when not in production environment.
 */
export const testIdDirective: AppDirective = {
    name: "test-id",
    hook: {
        created(el, binding) {
            // if (process.env.VITE_CURRENT_ENV === "PRODUCTION") return;

            let text = binding.value;
            const input = el?.querySelector("input") ?? undefined;
            // @ts-ignore
            const testIndex = binding.instance?.testIndex ?? null;

            if (binding?.arg) {
                text += `_${binding?.arg}`;
            }

            if (testIndex !== null) {
                text += `_${testIndex}`;
            }

            if (input) {
                input.setAttribute("data-testid", `${text}_input`);
            }

            el.setAttribute("data-testid", text);
        },
    },
};

/**
 * Apply directives to the app.
 * @param app
 * @param directives
 */
export const applyDirectives = (app: App, directives: AppDirective[]) => {
    directives.forEach(({ name, hook }) => {
        app.directive(name, hook);
    });
};
