import type {
    SelectedPriceOptionEnum,
    QuoteDescriptionBlockCreate,
} from "@/types/api/data-contracts";
import { TimePeriodEnum } from "@/types/TimePeriod";
import { INQUIRY_ICON_FALLBACK } from "@/constants";

export const oneTimeQuoteInitialData = {
    autoconfirm_orders: true as boolean | undefined,
    product_name: null as unknown as string,
    logo_url: INQUIRY_ICON_FALLBACK,
    sku: null as unknown as string,
    quantity: null as unknown as number,
    unit_of_measurement: "",
    case_size: null as unknown as number,
    unit: "",
    packaging_format: null as unknown as string,
    lead_time: null as unknown as number,
    delivery_date: null as unknown as string,
    vat_rate: null as unknown as number,
    payment_scenario: null as unknown as string,
    payment_delay: 0,
    quote_valid_for: null as unknown as number,
    quote_valid_for_unit: TimePeriodEnum.Days,
    product_description: null as unknown as string,
    currency: null as unknown as string,
    unit_price: null as unknown as number,
    total_price: null as unknown as number,
    shipping_cost: null as unknown as number,
    shipping_vat_rate: null as unknown as number,
    incoterms: null as unknown as string,
    dispatch_address: null as unknown as number,
    show_to_other_buyers: false,
    minimum_autoconfirm_order_quantity: null as unknown as number,
    maximum_autoconfirm_order_quantity: null as unknown as number,
    order_in_multiples_of: null as number | null,
    description_blocks: [] as QuoteDescriptionBlockCreate[],
    selected_price_option: "unit" as `${SelectedPriceOptionEnum}`,
};

export type OneTimeQuoteInitialData = typeof oneTimeQuoteInitialData;
