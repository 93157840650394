/* eslint-disable no-use-before-define */

import type { AxiosPromise } from "axios";

type UploadEventCallback = (
    modelId: string,
    payload: object
) => AxiosPromise<any>;

class UploadEventBus {
    private static instance: UploadEventBus;

    private callbacks: Map<string, UploadEventCallback>;

    private constructor() {
        this.callbacks = new Map();
    }

    static getInstance(): UploadEventBus {
        if (!UploadEventBus.instance) {
            UploadEventBus.instance = new UploadEventBus();
        }
        return UploadEventBus.instance;
    }

    addCallback(callbackId: string, callback: UploadEventCallback): void {
        this.callbacks.set(callbackId, callback);
    }

    removeCallback(callbackId: string): void {
        this.callbacks.delete(callbackId);
    }

    async executeCallback(
        callbackId: string,
        modelId: string,
        payload: object
    ): Promise<void> {
        const callback = this.callbacks.get(callbackId);
        if (callback) {
            await callback(modelId, payload);
            this.removeCallback(callbackId);
        }
    }
}

export const uploadEventBus = UploadEventBus.getInstance();
