import { minValue, required, requiredIf } from "@vuelidate/validators";
import type {
    QuoteRetrieve,
    PaymentScenarioEnum,
    QuoteDescriptionBlockCreate,
} from "@/types/api/data-contracts";
import { TimePeriodEnum } from "@/types/TimePeriod";

export const nonRecurringFormInitialState = {
    paymentTerm: null as null | string,
    paymentTermsOther: null as null | number,
    payment_scenario: "" as PaymentScenarioEnum,
    payment_term: "30",
    payment_delay: null as null | number,
    product_name: "",
    sku: "",
    quantity: null as null | number,
    unit: "",
    priceOption: "unit",
    price: null as null | number,
    currency: "",
    vat_rate: 0 as number,
    vat_percentage: null as unknown as number,
    delivery_date: "",
    quote_valid_for: null,
    quote_valid_for_unit: TimePeriodEnum.Days,
    country_of_origin: "",
    lead_time: null as null | string,
    product_description: "",
    shelf_life: undefined,
    shipping_cost: null as null | number,
    incoterms: null as null | string,
    dispatch_address: null as null | number,
    dispatch_address_full: null as null | string,
    minimum_order_quantity_for_other_buyers: null as null | string,
    maximum_order_quantity_for_other_buyers: null as null | string,
    show_to_other_buyers: false,
    packaging_format: "",
    description_blocks: [] as QuoteDescriptionBlockCreate[],
};

export const nonRecurringFormFields: Partial<keyof QuoteRetrieve>[] = [
    "payment_scenario",
    "product_name",
    "sku",
    "product_description",
    "vat_rate",
    "unit_of_measurement",
    "unit_price",
    "lead_time",
    "delivery_date",
    "quote_valid_for",
    "shipping_cost",
    "incoterms",
    "dispatch_address",
    "currency",
    "payment_delay",
    "quantity",
    "packaging_format",
];

export const getNonRecurringRules = (
    state: typeof nonRecurringFormInitialState
) => ({
    payment_scenario: { required },
    payment_delay: { required },
    paymentTerm: { required },
    product_name: { required },
    quantity: { required, minimum: minValue(0.00001) },
    unit: { required },
    priceOption: { required },
    price: { required },
    vat_percentage: { required },
    delivery_date: { required },
    payment_term: {},
    paymentTermsOther: {
        condition: requiredIf(state.payment_term === "other"),
    },
    quote_valid_for: { required },
    quote_valid_for_unit: { required },
    lead_time: { required },
    product_description: { required },
    sku: {},
    shipping_cost: { required },
    incoterms: { required },
    dispatch_address: { required },
    dispatch_address_full: { required },
    minimum_order_quantity_for_other_buyers: {
        condition: requiredIf(state.show_to_other_buyers),
    },
    maximum_order_quantity_for_other_buyers: {
        condition: requiredIf(state.show_to_other_buyers),
    },
    show_to_other_buyers: {},
    packaging_format: { required },
    country_of_origin: { required },
    description_blocks: {},
});
