import axiosInstance from "@/services/axiosInstance";
import type {
    ActionClearTriggerEnum,
    PaginatedNotificationList,
} from "@/types/api/data-contracts";

import type { AxiosPromise } from "axios";

class Notifications {
    // eslint-disable-next-line no-use-before-define
    private static instance: Notifications;

    public static getInstance(): Notifications {
        if (!Notifications.instance) {
            Notifications.instance = new Notifications();
        }
        return Notifications.instance;
    }

    /**
     * Perform a GET request to retrieve all notifications.
     * @todo Huzaifa, make sure the `used_for` parameter is correct, I retrieved from the backend code
     */
    getNotifications(
        page = 1,
        usedFor = "general_notifications"
    ): AxiosPromise<PaginatedNotificationList> {
        return axiosInstance.get("/notifications/notifications/", {
            params: { page, used_for: usedFor },
        });
    }

    /**
     * Perform a POST request to mark a notification as "read".
     */
    markNotificationAsRead(notificationUuid: string): AxiosPromise<void> {
        return axiosInstance.post(
            `/notifications/notifications/${notificationUuid}/mark-read/`
        );
    }

    /**
     * Mark all notifications related to the given object as read.
     * For example, you might provide `related_object_type` "quote" with `related_object_uuid`
     * "3fa85f64-5717-4562-b3fc-2c963f66afa6" to mark all notifications related to that quote as read.
     */
    markRelatedNotificationsAsRead(
        notificationUuid: string,
        relatedObjectType: "inquiry" | "quote" | "contract" | "order"
    ): AxiosPromise<void> {
        return axiosInstance.post(
            `/notifications/notifications/mark-related-read/`,
            {
                related_object_uuid: notificationUuid,
                related_object_type: relatedObjectType,
            }
        );
    }

    markActionAsRead = (actionUuid: string): AxiosPromise<void> =>
        axiosInstance.post(
            `/notifications/notifications/${actionUuid}/action-mark-read/`
        );

    markRelatedActionsAsCleared = (
        relatedObjectUuid: string | null,
        relatedObjectType:
            | "inquiry"
            | "quote"
            | "contract"
            | "order"
            | "ingredient"
            | "product",
        actionClearTrigger: ActionClearTriggerEnum
    ) => {
        const payload = {
            related_object_type: relatedObjectType,
            action_clear_trigger: actionClearTrigger,
            related_object_uuid: relatedObjectUuid,
        };

        axiosInstance.post(
            `/notifications/notifications/action-mark-related-cleared/`,
            payload
        );
    };

    /**
     * Mark a notification action as cleared by the user.
     * @param {string} actionUuid
     */
    markActionCleared(actionUuid: string) {
        return axiosInstance.post(
            `/notifications/notifications/${actionUuid}/action-mark-clear/`
        );
    }
}

export default Notifications;
