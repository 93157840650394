import type { QuoteDetail, QuoteRetrieve } from "@/types/api/data-contracts";

/**
 * Gets the lowest payment delay from an array of quotes.
 *
 * @param {QuoteDetail[]} quotes - An array of quote details.
 * @returns {number} The lowest payment delay found in the quotes, or 60 if no valid delay is found.
 */
export const getLowestDelayInQuotes = (
    quotes: (QuoteDetail | QuoteRetrieve)[]
) => {
    if (!quotes.length) return 0;

    return quotes.reduce(
        (lowest, quote) =>
            quote.payment_delay !== undefined && quote.payment_delay < lowest
                ? quote.payment_delay
                : lowest,
        quotes[0].payment_delay || 0
    );
};
