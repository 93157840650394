import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type {
    EmailNotifications,
    PaginatedDashboardInquiriesList,
    PaginatedDashboardOrdersList,
    SupplierCompany,
    SupplierDashboardCount,
    SupplierInquiryResponse,
} from "@/types/api/data-contracts";

class SupplierService {
    // eslint-disable-next-line no-use-before-define
    private static instance: SupplierService;

    public static getInstance(): SupplierService {
        if (!SupplierService.instance) {
            SupplierService.instance = new SupplierService();
        }
        return SupplierService.instance;
    }

    onBoard(payload: object) {
        return axiosInstance.post("companies/onboarding/supplier/", payload);
    }

    getSupplierById(companyId: string): AxiosPromise<SupplierCompany> {
        return axiosInstance.get(`companies/suppliers/${companyId}/`);
    }

    updateInfo(payload: object, companyId: string) {
        return axiosInstance.put(`companies/suppliers/${companyId}/`, payload);
    }

    getDocuments(companyId: string) {
        return axiosInstance.get(`companies/suppliers/${companyId}/documents/`);
    }

    uploadDocument(companyId: string, formData: FormData) {
        return axiosInstance.post(
            `companies/suppliers/${companyId}/documents/`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    }

    deleteDocument(companyId: string, documentId: string) {
        return axiosInstance.delete(
            `companies/suppliers/${companyId}/documents/${documentId}/`
        );
    }

    getInquiries(
        filter?: {
            // eslint-disable-next-line camelcase
            supplier_state: string | null;
            name?: string | null;
            category?: string | null;
            page?: number | undefined;
        },
        ordering?: string | null
    ) {
        return axiosInstance.get("inquiries/supplier/", {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    getInquiryDetails(
        inquiryId: string
    ): AxiosPromise<SupplierInquiryResponse> {
        return axiosInstance.get(`inquiries/supplier/${inquiryId}/`);
    }

    /**
     * Endpoint to retrieve requests list for Supplier's dashboard.
     * @param page
     */
    getDashboardInquiries(
        page = 0
    ): AxiosPromise<PaginatedDashboardInquiriesList> {
        return axiosInstance.get(`/inquiries/supplier/dashboard-inquiries/`, {
            params: {
                page,
            },
        });
    }

    getDashboardOrders(
        page: number
    ): AxiosPromise<PaginatedDashboardOrdersList> {
        return axiosInstance.get(`/orders/supplier/dashboard-orders/`, {
            params: { page },
        });
    }

    getEmailPreferences() {
        const url = `/notifications/emails/supplier/`;
        return axiosInstance.get(url);
    }

    updateEmailPreference(id: number, value: boolean) {
        const url = `/notifications/emails/supplier/${id}/`;
        return axiosInstance.put(url, {
            is_activated_for_user: value,
        });
    }

    updateEmailGroupsPreference(
        type: string,
        value: boolean
    ): AxiosPromise<EmailNotifications> {
        const url = `/notifications/emails/supplier/on-off-unsubscribe-groups/`;
        return axiosInstance.put(url, {
            type,
            is_activated_for_user: value,
        });
    }

    unSubscribeEmails(value: boolean) {
        const url = "/notifications/emails/supplier/all/";
        return axiosInstance.post(url, {
            all_emails: value,
        });
    }

    updateQuestionnaire(payload: object, supplierId: string) {
        const url = `/companies/suppliers/${supplierId}/questionnaire/`;
        return axiosInstance.put(url, payload);
    }

    checkQuestionnaireStatus() {
        const url = "/companies/suppliers/questionnaire_submitted/";
        return axiosInstance.get(url);
    }

    addCompanyAddress(payload: object, supplierId: string) {
        const url = `/companies/suppliers/${supplierId}/`;
        return axiosInstance.patch(url, payload);
    }

    declineInquiry(inquiryId: string, payload: object) {
        const url = `/inquiries/supplier/${inquiryId}/decline/`;
        return axiosInstance.post(url, payload);
    }

    buyerSummary(uuid: string) {
        return axiosInstance.post(`/companies/suppliers/buyer-summary/`, {
            uuid,
        });
    }

    getSupplierStats(): AxiosPromise<SupplierDashboardCount> {
        return axiosInstance.get("/companies/suppliers/dashboard/count/");
    }
}

export default SupplierService;
