import type { QuoteDetail } from "@/types/api/data-contracts";
import { isQuoteExpired } from "@/utils";

/**
 * Filters and returns quotes from the basket that are not expired.
 *
 * This function checks each quote in the provided array to determine if it is expired
 * using the `isQuoteExpired` function. Only quotes that are not expired are included in
 * the returned array. If the input is `undefined` or an empty array, the function returns
 * an empty array, indicating that there are no valid quotes to be returned.
 *
 * @param {QuoteDetail[] | undefined} basketQuotes - An array of quote details in the basket,
 * or `undefined` if the basket has not been initialized.
 * @returns {QuoteDetail[]} An array of quote details that are not expired. If there are no
 * valid quotes, or the input is `undefined` or empty, an empty array is returned.
 */
export const getValidBasketQuotes = (
    basketQuotes: QuoteDetail[] | undefined
) => {
    if (!basketQuotes || !basketQuotes.length) return [];

    return basketQuotes.filter((basketQuote) => !isQuoteExpired(basketQuote));
};
