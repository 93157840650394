import type { AppAction } from "@/types";

/**
 * Just a test action...
 * @todo delete me...
 */
export const testAction: AppAction<{
    actionParam: string;
    eventParam: string;
}> = (args) => {
    console.log(">> my first action");
    console.log(">> event", args.eventParam);
    console.log(">> action", args.actionParam);

    return true;
};
