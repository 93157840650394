import dayjs from "dayjs";

const DATE_FORMAT = "DD MMM YY";

export const formatDate = (date: string, format = DATE_FORMAT) => {
    if (date) {
        return dayjs(date).format(format);
    }
    return "--";
};

export default formatDate;
