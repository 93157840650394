import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type IUserInformation from "@/types/UserInformation";
import type {
    UserTokenResponse,
    PaginatedCompanyMembersListList,
    UserInviteCreate,
    EmailInviteResponse,
    UserInviteAcceptRequest,
    CreateUser,
    CannyTokenResponse,
} from "@/types/api/data-contracts";

class UserService {
    // eslint-disable-next-line no-use-before-define
    private static instance: UserService;

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    signin(payload: object) {
        return axiosInstance.post("users/login/", payload);
    }

    /**
     *
     * @param payload
     * @param {User} userId
     */
    getInfo(): AxiosPromise<IUserInformation> {
        return axiosInstance.get("users/users/info/");
    }

    /**
     *
     * @param payload
     * @param {User} userId
     */
    updateInfo(payload: object, userId: string) {
        return axiosInstance.put(`users/users/${userId}/`, payload);
    }

    updatePassword(payload: object) {
        return axiosInstance.put("users/users/update-password/", payload);
    }

    resetPasswordInit(payload: object) {
        return axiosInstance.post("users/reset-password/init/", payload);
    }

    resetPasswordCommit(payload: object) {
        return axiosInstance.post("users/reset-password/commit/", payload);
    }

    getUserTokenForMasqueradeMode(
        token: string
    ): AxiosPromise<UserTokenResponse> {
        const url = "/users/admin_panel/get_token/";
        return axiosInstance.post(url, {
            token,
        });
    }

    getCompanyMembers(
        page: number
    ): AxiosPromise<PaginatedCompanyMembersListList> {
        const url = "/users/users/company-members/";
        return axiosInstance.get(url, { params: { page } });
    }

    inviteCompanyMember(
        payload: UserInviteCreate
    ): AxiosPromise<EmailInviteResponse> {
        const url = "/users/users/invite-user/";
        return axiosInstance.post(url, payload);
    }

    removeCompanyMember(email: string): AxiosPromise<void> {
        const url = "/users/users/remove-member/";
        return axiosInstance.post(url, { email });
    }

    createCompanyMember(
        payload: UserInviteAcceptRequest
    ): AxiosPromise<CreateUser> {
        const url = "/users/users/create-member/";
        return axiosInstance.post(url, payload);
    }

    getCannySSOToken(): AxiosPromise<CannyTokenResponse> {
        const url = "/canny/generate-token/";
        return axiosInstance.get(url);
    }

    getParagonToken(): AxiosPromise {
        const url = "/paragon/authenticate";
        return axiosInstance.get(url);
    }

    notifyAppConnection(payload: Record<string, string>): AxiosPromise<void> {
        const url = "/paragon/app_connected";
        return axiosInstance.post(url, payload);
    }

    notifyAppDisconnection(
        payload: Record<string, string>
    ): AxiosPromise<void> {
        const url = "/paragon/app_disconnected";
        return axiosInstance.post(url, payload);
    }
}

export default UserService;
