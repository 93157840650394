import { ref, watchEffect } from "vue";
import UserService from "@/services/User";
import * as Sentry from "@sentry/vue";
import {
    paragon,
    SDK_EVENT,
    type AuthenticatedConnectUser,
    type IntegrationInstallEvent,
    type IntegrationUninstallEvent,
} from "@useparagon/connect";
import { useAuthStore } from "@/stores/userAuth";
import {
    enabledIntegrationsForBuyer,
    enabledIntegrationsForSupplier,
} from "@/constants";

const paragonConnectedUser = ref<AuthenticatedConnectUser>();
const loading = ref(true);

export const useParagon = () => {
    const authStore = useAuthStore();
    const userService = UserService.getInstance();

    /**
     * retrieve and store the connected user data
     */
    const fetchParagonUser = () => {
        const paragonUser =
            paragon.getUser() as unknown as AuthenticatedConnectUser;
        paragonConnectedUser.value = paragonUser;
    };

    /**
     * Fetch user's paragon token from our BE
     * to authenticate the user with Paragon
     */
    const authenticateParagonUser = async () => {
        try {
            if (import.meta.env.VITE_CURRENT_ENV !== "LOCAL") {
                const response = await userService.getParagonToken();
                const { token } = response.data;
                await paragon.authenticate(
                    import.meta.env.VITE_PARAGON_PROJECT_ID,
                    token
                );
            }

            fetchParagonUser();
            loading.value = false; // Set loading to false when user is authenticated
        } catch (error) {
            Sentry.captureException(error);
        }
    };
    /**
     * Check if we have enabled this integration on Paragon
     * @param integrationName
     */
    const isIntegrationEnabled = (integrationName: string) => {
        if (authStore.companyData.company_type === "brand") {
            return (
                enabledIntegrationsForBuyer.indexOf(
                    integrationName.toLowerCase()
                ) > -1
            );
        }
        return (
            enabledIntegrationsForSupplier.indexOf(
                integrationName.toLowerCase()
            ) > -1
        );
    };

    /**
     * check if the user has connected an integration
     * @param integrationName
     */
    const isIntegrationConnected = (integrationName: string) =>
        paragonConnectedUser?.value?.integrations[integrationName.toLowerCase()]
            ?.enabled === true ?? false;

    /**
     * shows the connection modal which allows the user to link their integration
     * @param integrationName
     */
    const showConnectionModal = (integrationName: string) => {
        // @ts-ignore
        paragon.connect(integrationName.toLowerCase());
    };

    watchEffect(() => {
        if (paragonConnectedUser.value?.authenticated) {
            loading.value = false;
        }
    });

    const subscribeToParagonEvents = () => {
        /**
         * Once a user connects their integration with Paragon we notify
         * backend about the connected integration and refresh the user data
         */
        paragon.subscribe(
            SDK_EVENT.ON_INTEGRATION_INSTALL,
            async (
                evt: IntegrationInstallEvent,
                user: AuthenticatedConnectUser
            ) => {
                if (user && isIntegrationEnabled(evt.integrationType)) {
                    await userService.notifyAppConnection({
                        integration_name: evt.integrationType,
                        url: window.location.href,
                    });
                    fetchParagonUser();
                }
            }
        );
        /**
         * Once a user disconnects any integration we notify BE about disconnection
         * and then fetch the user data
         */
        paragon.subscribe(
            SDK_EVENT.ON_INTEGRATION_UNINSTALL,
            async (evt: IntegrationUninstallEvent) => {
                await userService.notifyAppDisconnection({
                    integration_name: evt.integrationType,
                    url: window.location.href,
                });
                fetchParagonUser();
            }
        );
    };

    const unSubscribeToParagonEvents = () => {
        paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, () => {});
        paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, () => {});
    };

    return {
        loading,
        fetchParagonUser,
        showConnectionModal,
        isIntegrationEnabled,
        paragonConnectedUser,
        isIntegrationConnected,
        authenticateParagonUser,
        subscribeToParagonEvents,
        unSubscribeToParagonEvents,
    };
};
