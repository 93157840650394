import Notifications from "@/services/Notifications";
import type { AppAction } from "@/types";
import type { ActionClearTriggerEnum } from "@/types/api/data-contracts";
import { useActionsStore } from "@/stores/actions";
import { useAuthStore } from "@/stores/userAuth";

/**
 * Action to mark all related actions as clear.
 * @param {Record<inquiry, InquiryResponse>} args
 * @return {boolean} true in order to continue the loop of actions.
 *
 * @todo Add a call to mark related actions as cleared.
 */
export const markRelatedActionsCleared: AppAction<{
    relatedObjectUuid: string;
    relatedObjectType:
        | "inquiry"
        | "quote"
        | "contract"
        | "order"
        | "ingredient"
        | "product";

    actionClearTrigger: ActionClearTriggerEnum | ActionClearTriggerEnum[];
}> = async (args) => {
    const authStore = useAuthStore();

    const { relatedObjectUuid, relatedObjectType, actionClearTrigger } = args;

    const notificationsService = Notifications.getInstance();
    const actionsStore = useActionsStore();

    if (Array.isArray(actionClearTrigger)) {
        actionClearTrigger.forEach((clearTrigger) => {
            notificationsService.markRelatedActionsAsCleared(
                relatedObjectUuid,
                relatedObjectType,
                clearTrigger
            );
        });
    } else {
        await notificationsService.markRelatedActionsAsCleared(
            relatedObjectUuid,
            relatedObjectType,
            actionClearTrigger
        );
    }

    if (!authStore.masqueradeMode) {
        await actionsStore.initActions();
    }

    return true; // Needed to run the next action!
};
