import dayjs from "dayjs";

interface PayloadWithDates extends Record<string, any> {
    first_order_delivery_date: string | null | undefined | Date;
    due_date?: string | null | Date;
}

/**
 * Given a payload that contains date fields, formats them to match API requirements.
 * @todo Put date fields in an array and loop through them
 * @todo Create a function to apply the datejs transformation.
 * @param payload
 */
export const formatPayloadDates = (payload: PayloadWithDates) => {
    const newPayload = payload;
    if (newPayload.first_order_delivery_date) {
        if (newPayload.first_order_delivery_date === "--") {
            newPayload.first_order_delivery_date = null;
        } else {
            newPayload.first_order_delivery_date = dayjs(
                newPayload.first_order_delivery_date as string
            ).format("YYYY-MM-DD");
        }
    }

    if (newPayload.due_date) {
        if (newPayload.due_date === "--") {
            newPayload.due_date = null;
        } else {
            newPayload.due_date = dayjs(newPayload.due_date as string).format(
                "YYYY-MM-DD"
            );
        }
    }
};