import type { AppAction } from "@/types";

/**
 * Action to update the current product forecast settings when the forecasting period is updated.
 * @param args
 */
export const updateProductForecastSettings: AppAction<{
    productUuid: string;
    fetchAndPopulateProductForecast: () => Promise<void>;
}> = async (args) => {
    const { fetchAndPopulateProductForecast } = args;

    await fetchAndPopulateProductForecast();

    return true;
};
