import Notifications from "@/services/Notifications";
import type { AppAction } from "@/types";

import { useActionsStore } from "@/stores/actions";
import { useAuthStore } from "@/stores/userAuth";

/**
 * Action to mark a specific action as cleared.
 * @param {Record<"actionUuid", string>} args
 * @return {boolean} true in order to continue the loop of actions.
 *
 * @todo Add a call to mark related actions as cleared.
 */
export const markActionCleared: AppAction<{
    actionUuid: string;
}> = async (args: { actionUuid: string }): Promise<boolean> => {
    const authStore = useAuthStore();

    const { actionUuid } = args;

    const notificationsService = Notifications.getInstance();
    const actionsStore = useActionsStore();

    await notificationsService.markActionCleared(actionUuid);

    if (!authStore.masqueradeMode) {
        await actionsStore.initActions();
    }

    return true; // Needed to run the next action!
};
