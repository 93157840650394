import { computed, onUnmounted, ref, watch } from "vue";
import type {
    AppModal,
    AppModalProps,
    AppModalInstance,
    ModalId,
    AppModalCallback,
} from "@/types";
import { keys } from "lodash";

type CallbackTypes = "modalHide" | "modalShow";

const appModals = ref<Record<string, AppModal>>({});
export const useModals = (modalName: ModalId) => {
    const getModal = computed(() => appModals.value[modalName]);

    /**
     * A modelValue property against the visible prop
     * of the current Modal (a single element of the appModals object).
     * Should be used as modelValue in any Modal component registered with useModals.
     */
    const isVisible = computed({
        get() {
            const modal = getModal.value;

            if (!modal) return false;

            return modal.modalProps.visible;
        },
        set(newVisibility: boolean) {
            const modal = getModal.value;

            if (!modal) return;

            modal.emit("update:visible", newVisibility);
        },
    });

    const unregisterModal = () => {
        delete appModals.value[modalName];
    };

    const hideModal = () => {
        if (!getModal.value) return;

        getModal.value.emit("update:visible", false);
    };

    const showModal = () => {
        if (!getModal.value) {
            console.warn(
                `Opply Warning: Modal '${modalName}' not registered yet. Use "registerAppModal" first.`
            );
            return false;
        }
        getModal.value.emit("update:visible", true);
        return true;
    };

    const unregisterCallback = (
        callbackFunction: () => void,
        type: CallbackTypes
    ) => {
        const callbacksTypename = `${type}Callbacks` as
            | "modalHideCallbacks"
            | "modalShowCallbacks";

        const callbacks = getModal.value[
            callbacksTypename
        ] as AppModalCallback[];

        if (!callbacks?.length) return;

        appModals.value[modalName] = {
            ...getModal.value,
            [callbacksTypename]: callbacks.filter(
                (callback) => callback.execute !== callbackFunction
            ),
        };
    };

    const onHide = (callbackFunction: () => void, oneOff = false) => {
        if (!getModal.value) return;

        if (!getModal.value?.modalHideCallbacks) {
            getModal.value.modalHideCallbacks = [];
        }

        const callback = {
            execute: callbackFunction,
            oneOff,
        };

        getModal.value.modalHideCallbacks.push(callback);
    };

    const onShow = (callback: AppModalCallback) => {
        if (!getModal.value) return;

        if (!getModal.value.modalShowCallbacks) {
            getModal.value.modalShowCallbacks = [];
        }

        getModal.value.modalShowCallbacks.push(callback);
    };

    const handleCancelClick = () => {
        if (!getModal.value) return;

        hideModal();

        getModal.value.emit("click:cancel");
    };

    const unregisterCallbacks = (
        callbacks: (() => void)[],
        type: CallbackTypes
    ) => {
        callbacks.forEach((callback) => unregisterCallback(callback, type));
    };

    const executeModalHideCallbacks = () => {
        const callbacksToUnregister: (() => void)[] = [];

        getModal.value.modalHideCallbacks?.forEach((callback) => {
            callback.execute();

            if (callback.oneOff) {
                callbacksToUnregister.push(callback.execute);
            }
        });

        if (!callbacksToUnregister.length) return;

        unregisterCallbacks(callbacksToUnregister, "modalHide");
    };

    const executeModalShowCallbacks = () => {
        getModal.value.modalShowCallbacks?.forEach((callback) => {
            callback.execute();
        });
    };

    const getAppModal = computed((): AppModalInstance | {} => ({
        isVisible,
        showModal,
        hideModal,
        unregisterModal,
        handleCancelClick,
        onHide,
        onShow,
    }));

    const registerAppModal = (
        modalProps: AppModalProps,
        emit: (
            event: "update:visible" | "click:cancel" | "click:submit",
            ...args: any[]
        ) => void
    ) => {
        if (keys(appModals).includes(modalName)) {
            throw new Error(`Modal name already in use: ${modalName}`);
        }

        appModals.value[modalName] = {
            modalName,
            modalProps,
            emit,
        };

        onUnmounted(() => {
            unregisterModal();
        });

        watch(isVisible, () => {
            if (!isVisible.value) {
                executeModalHideCallbacks();
            } else {
                executeModalShowCallbacks();
            }
        });

        return getAppModal.value;
    };

    return {
        getAppModal,
        isVisible,
        registerAppModal,
        showModal,
        hideModal,
        unregisterModal,
        handleCancelClick,
        onHide,
        onShow,
    };
};
