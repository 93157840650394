export const countries = [
    { id: "AF", value: "+93", country: "Afghanistan", code: "AF" },
    { id: "AL", value: "+355", country: "Albania", code: "AL" },
    { id: "DZ", value: "+213", country: "Algeria", code: "DZ" },
    { id: "AS", value: "+1 684", country: "American Samoa", code: "AS" },
    { id: "AD", value: "+376", country: "Andorra", code: "AD" },
    { id: "AO", value: "+244", country: "Angola", code: "AO" },
    { id: "AI", value: "+1 264", country: "Anguilla", code: "AI" },
    { id: "AQ", value: "+672", country: "Antarctica", code: "AQ" },
    { id: "AG", value: "+1268", country: "Antigua and Barbuda", code: "AG" },
    { id: "AR", value: "+54", country: "Argentina", code: "AR" },
    { id: "AM", value: "+374", country: "Armenia", code: "AM" },
    { id: "AW", value: "+297", country: "Aruba", code: "AW" },
    { id: "AU", value: "+61", country: "Australia", code: "AU" },
    { id: "AT", value: "+43", country: "Austria", code: "AT", supported: true },
    { id: "AZ", value: "+994", country: "Azerbaijan", code: "AZ" },
    { id: "BS", value: "+1 242", country: "Bahamas", code: "BS" },
    { id: "BH", value: "+973", country: "Bahrain", code: "BH" },
    { id: "BD", value: "+880", country: "Bangladesh", code: "BD" },
    { id: "BB", value: "+1 246", country: "Barbados", code: "BB" },
    { id: "BY", value: "+375", country: "Belarus", code: "BY" },
    { id: "BE", value: "+32", country: "Belgium", code: "BE", supported: true },
    { id: "BZ", value: "+501", country: "Belize", code: "BZ" },
    { id: "BJ", value: "+229", country: "Benin", code: "BJ" },
    { id: "BM", value: "+1 441", country: "Bermuda", code: "BM" },
    { id: "BT", value: "+975", country: "Bhutan", code: "BT" },
    { id: "BO", value: "+591", country: "Bolivia", code: "BO" },
    { id: "BA", value: "+387", country: "Bosnia and Herzegovina", code: "BA" },
    { id: "BW", value: "+267", country: "Botswana", code: "BW" },
    { id: "BV", value: "+55", country: "Bouvet Island", code: "BV" },
    { id: "BR", value: "+55", country: "Brazil", code: "BR" },
    {
        id: "IO",
        value: "+246",
        country: "British Indian Ocean Territory",
        code: "IO",
    },
    { id: "BN", value: "+673", country: "Brunei Darussalam", code: "BN" },
    { id: "BG", value: "+359", country: "Bulgaria", code: "BG" },
    { id: "BF", value: "+226", country: "Burkina Faso", code: "BF" },
    { id: "BI", value: "+257", country: "Burundi", code: "BI" },
    { id: "KH", value: "+855", country: "Cambodia", code: "KH" },
    { id: "CM", value: "+237", country: "Cameroon", code: "CM" },
    { id: "CA", value: "+1", country: "Canada", code: "CA", supported: true },
    { id: "CV", value: "+238", country: "Cape Verde", code: "CV" },
    { id: "KY", value: "+ 345", country: "Cayman Islands", code: "KY" },
    {
        id: "CF",
        value: "+236",
        country: "Central African Republic",
        code: "CF",
    },
    { id: "TD", value: "+235", country: "Chad", code: "TD" },
    { id: "CL", value: "+56", country: "Chile", code: "CL" },
    { id: "CN", value: "+86", country: "China", code: "CN" },
    { id: "CX", value: "+61", country: "Christmas Island", code: "CX" },
    { id: "CC", value: "+61", country: "Cocos (Keeling) Islands", code: "CC" },
    { id: "CO", value: "+57", country: "Colombia", code: "CO" },
    { id: "KM", value: "+269", country: "Comoros", code: "KM" },
    { id: "CG", value: "+242", country: "Congo", code: "CG" },
    {
        id: "CD",
        value: "+243",
        country: "Congo, Democratic Republic",
        code: "CD",
    },
    { id: "CK", value: "+682", country: "Cook Islands", code: "CK" },
    { id: "CR", value: "+506", country: "Costa Rica", code: "CR" },
    { id: "CI", value: "+225", country: "Cote D'Ivoire", code: "CI" },
    { id: "HR", value: "+385", country: "Croatia", code: "HR" },
    { id: "CU", value: "+53", country: "Cuba", code: "CU" },
    { id: "CY", value: "+357", country: "Cyprus", code: "CY", supported: true },
    { id: "CZ", value: "+420", country: "Czech Republic", code: "CZ" },
    { id: "DK", value: "+45", country: "Denmark", code: "DK" },
    { id: "DJ", value: "+253", country: "Djibouti", code: "DJ" },
    { id: "DM", value: "+1 767", country: "Dominica", code: "DM" },
    { id: "DO", value: "+1 809", country: "Dominican Republic", code: "DO" },
    { id: "EC", value: "+593", country: "Ecuador", code: "EC" },
    { id: "EG", value: "+20", country: "Egypt", code: "EG" },
    { id: "SV", value: "+503", country: "El Salvador", code: "SV" },
    { id: "GQ", value: "+240", country: "Equatorial Guinea", code: "GQ" },
    { id: "ER", value: "+291", country: "Eritrea", code: "ER" },
    {
        id: "EE",
        value: "+372",
        country: "Estonia",
        code: "EE",
        supported: true,
    },
    { id: "ET", value: "+251", country: "Ethiopia", code: "ET" },
    {
        id: "FK",
        value: "+500",
        country: "Falkland Islands (Malvinas)",
        code: "FK",
    },
    { id: "FO", value: "+298", country: "Faroe Islands", code: "FO" },
    { id: "FJ", value: "+679", country: "Fiji", code: "FJ" },
    {
        id: "FI",
        value: "+358",
        country: "Finland",
        code: "FI",
        supported: true,
    },
    { id: "FR", value: "+33", country: "France", code: "FR", supported: true },
    { id: "GF", value: "+594", country: "French Guiana", code: "GF" },
    { id: "PF", value: "+689", country: "French Polynesia", code: "PF" },
    {
        id: "TF",
        value: "+262",
        country: "French Southern Territories",
        code: "TF",
    },
    { id: "GA", value: "+241", country: "Gabon", code: "GA" },
    { id: "GM", value: "+220", country: "Gambia", code: "GM" },
    { id: "GE", value: "+995", country: "Georgia", code: "GE" },
    { id: "DE", value: "+49", country: "Germany", code: "DE", supported: true },
    { id: "GH", value: "+233", country: "Ghana", code: "GH" },
    { id: "GI", value: "+350", country: "Gibraltar", code: "GI" },
    {
        id: "GB",
        value: "+44",
        country: "Great Britain",
        code: "GB",
        supported: true,
    },
    { id: "GR", value: "+30", country: "Greece", code: "GR", supported: true },
    { id: "GL", value: "+299", country: "Greenland", code: "GL" },
    { id: "GD", value: "+1 473", country: "Grenada", code: "GD" },
    { id: "GP", value: "+590", country: "Guadeloupe", code: "GP" },
    { id: "GU", value: "+1 671", country: "Guam", code: "GU" },
    { id: "GT", value: "+502", country: "Guatemala", code: "GT" },
    { id: "GG", value: "+44 1481", country: "Guernsey", code: "GG" },
    { id: "GN", value: "+224", country: "Guinea", code: "GN" },
    { id: "GW", value: "+245", country: "Guinea-Bissau", code: "GW" },
    { id: "GY", value: "+595", country: "Guyana", code: "GY" },
    { id: "HT", value: "+509", country: "Haiti", code: "HT" },
    {
        id: "HM",
        value: "+672",
        country: "Heard Island & Mcdonald Islands",
        code: "HM",
    },
    {
        id: "VA",
        value: "+379",
        country: "Holy See (Vatican City State)",
        code: "VA",
    },
    { id: "HN", value: "+504", country: "Honduras", code: "HN" },
    { id: "HK", value: "+852", country: "Hong Kong", code: "HK" },
    { id: "HU", value: "+36", country: "Hungary", code: "HU" },
    { id: "IS", value: "+354", country: "Iceland", code: "IS" },
    { id: "IN", value: "+91", country: "India", code: "IN" },
    { id: "ID", value: "+62", country: "Indonesia", code: "ID" },
    {
        id: "IR",
        value: "+98",
        country: "Iran, Islamic Republic Of",
        code: "IR",
    },
    { id: "IQ", value: "+964", country: "Iraq", code: "IQ" },
    {
        id: "IE",
        value: "+353",
        country: "Ireland",
        code: "IE",
        supported: true,
    },
    { id: "IM", value: "+44 1624", country: "Isle of Man", code: "IM" },
    { id: "IL", value: "+972", country: "Israel", code: "IL" },
    { id: "IT", value: "+39", country: "Italy", code: "IT", supported: true },
    { id: "JM", value: "+1 876", country: "Jamaica", code: "JM" },
    { id: "JP", value: "+81", country: "Japan", code: "JP" },
    { id: "JE", value: "+44 1534", country: "Jersey", code: "JE" },
    { id: "JO", value: "+962", country: "Jordan", code: "JO" },
    { id: "KZ", value: "+7", country: "Kazakhstan", code: "KZ" },
    { id: "KE", value: "+254", country: "Kenya", code: "KE" },
    { id: "KI", value: "+686", country: "Kiribati", code: "KI" },
    { id: "KR", value: "+82", country: "Korea", code: "KR" },
    { id: "KW", value: "+965", country: "Kuwait", code: "KW" },
    { id: "KG", value: "+996", country: "Kyrgyzstan", code: "KG" },
    {
        id: "LA",
        value: "+856",
        country: "Lao People's Democratic Republic",
        code: "LA",
    },
    { id: "LV", value: "+371", country: "Latvia", code: "LV", supported: true },
    { id: "LB", value: "+961", country: "Lebanon", code: "LB" },
    { id: "LS", value: "+266", country: "Lesotho", code: "LS" },
    { id: "LR", value: "+231", country: "Liberia", code: "LR" },
    { id: "LY", value: "+218", country: "Libyan Arab Jamahiriya", code: "LY" },
    { id: "LI", value: "+423", country: "Liechtenstein", code: "LI" },
    {
        id: "LT",
        value: "+370",
        country: "Lithuania",
        code: "LT",
        supported: true,
    },
    {
        id: "LU",
        value: "+352",
        country: "Luxembourg",
        code: "LU",
        supported: true,
    },
    { id: "MO", value: "+853", country: "Macao", code: "MO" },
    { id: "MK", value: "+389", country: "Macedonia", code: "MK" },
    { id: "MG", value: "+261", country: "Madagascar", code: "MG" },
    { id: "MW", value: "+265", country: "Malawi", code: "MW" },
    { id: "MY", value: "+60", country: "Malaysia", code: "MY" },
    { id: "MV", value: "+960", country: "Maldives", code: "MV" },
    { id: "ML", value: "+223", country: "Mali", code: "ML" },
    { id: "MT", value: "+356", country: "Malta", code: "MT", supported: true },
    { id: "MH", value: "+692", country: "Marshall Islands", code: "MH" },
    { id: "MQ", value: "+596", country: "Martinique", code: "MQ" },
    { id: "MR", value: "+222", country: "Mauritania", code: "MR" },
    { id: "MU", value: "+230", country: "Mauritius", code: "MU" },
    { id: "YT", value: "+262", country: "Mayotte", code: "YT" },
    { id: "MX", value: "+52", country: "Mexico", code: "MX" },
    {
        id: "FM",
        value: "+691",
        country: "Micronesia, Federated States Of",
        code: "FM",
    },
    { id: "MD", value: "+373", country: "Moldova", code: "MD" },
    { id: "MC", value: "+377", country: "Monaco", code: "MC" },
    { id: "MN", value: "+976", country: "Mongolia", code: "MN" },
    { id: "ME", value: "+382", country: "Montenegro", code: "ME" },
    { id: "MS", value: "+1664", country: "Montserrat", code: "MS" },
    { id: "MA", value: "+212", country: "Morocco", code: "MA" },
    { id: "MZ", value: "+258", country: "Mozambique", code: "MZ" },
    { id: "MM", value: "+95", country: "Myanmar", code: "MM" },
    { id: "NA", value: "+264", country: "Namibia", code: "NA" },
    { id: "NR", value: "+674", country: "Nauru", code: "NR" },
    { id: "NP", value: "+977", country: "Nepal", code: "NP" },
    {
        id: "NL",
        value: "+31",
        country: "Netherlands",
        code: "NL",
        supported: true,
    },
    { id: "NC", value: "+687", country: "New Caledonia", code: "NC" },
    { id: "NZ", value: "+64", country: "New Zealand", code: "NZ" },
    { id: "NI", value: "+505", country: "Nicaragua", code: "NI" },
    { id: "NE", value: "+227", country: "Niger", code: "NE" },
    { id: "NG", value: "+234", country: "Nigeria", code: "NG" },
    { id: "NU", value: "+683", country: "Niue", code: "NU" },
    { id: "NF", value: "+672", country: "Norfolk Island", code: "NF" },
    { id: "KP", value: "+850", country: "North Korea", code: "KP" },
    {
        id: "XI",
        value: "+44",
        country: "Northern Ireland",
        code: "XI",
        supported: true,
    },
    {
        id: "MP",
        value: "+1 670",
        country: "Northern Mariana Islands",
        code: "MP",
    },
    { id: "NO", value: "+47", country: "Norway", code: "NO" },
    { id: "OM", value: "+968", country: "Oman", code: "OM" },
    { id: "PK", value: "+92", country: "Pakistan", code: "PK" },
    { id: "PW", value: "+680", country: "Palau", code: "PW" },
    {
        id: "PS",
        value: "+970",
        country: "Palestinian Territory, Occupied",
        code: "PS",
    },
    { id: "PA", value: "+507", country: "Panama", code: "PA" },
    { id: "PG", value: "+675", country: "Papua New Guinea", code: "PG" },
    { id: "PY", value: "+595", country: "Paraguay", code: "PY" },
    { id: "PE", value: "+51", country: "Peru", code: "PE" },
    { id: "PH", value: "+63", country: "Philippines", code: "PH" },
    { id: "PN", value: "+64", country: "Pitcairn", code: "PN" },
    { id: "PL", value: "+48", country: "Poland", code: "PL" },
    {
        id: "PT",
        value: "+351",
        country: "Portugal",
        code: "PT",
        supported: true,
    },
    { id: "PR", value: "+1 787", country: "Puerto Rico", code: "PR" },
    { id: "QA", value: "+974", country: "Qatar", code: "QA" },
    { id: "RE", value: "+262", country: "Reunion", code: "RE" },
    { id: "RO", value: "+40", country: "Romania", code: "RO" },
    { id: "RU", value: "+7", country: "Russian Federation", code: "RU" },
    { id: "RW", value: "+250", country: "Rwanda", code: "RW" },
    { id: "BL", value: "+590", country: "Saint Barthelemy", code: "BL" },
    { id: "SH", value: "+290", country: "Saint Helena", code: "SH" },
    { id: "KN", value: "+1 869", country: "Saint Kitts And Nevis", code: "KN" },
    { id: "LC", value: "+1 758", country: "Saint Lucia", code: "LC" },
    { id: "MF", value: "+590", country: "Saint Martin", code: "MAF" },
    {
        id: "PM",
        value: "+508",
        country: "Saint Pierre And Miquelon",
        code: "PM",
    },
    {
        id: "VC",
        value: "+1 784",
        country: "Saint Vincent And Grenadines",
        code: "VC",
    },
    { id: "WS", value: "+685", country: "Samoa", code: "WS" },
    { id: "SM", value: "+378", country: "San Marino", code: "SM" },
    { id: "ST", value: "+239", country: "Sao Tome And Principe", code: "ST" },
    { id: "SA", value: "+966", country: "Saudi Arabia", code: "SA" },
    { id: "SN", value: "+221", country: "Senegal", code: "SN" },
    { id: "RS", value: "+381", country: "Serbia", code: "RS" },
    { id: "SC", value: "+248", country: "Seychelles", code: "SC" },
    { id: "SL", value: "+232", country: "Sierra Leone", code: "SL" },
    { id: "SG", value: "+65", country: "Singapore", code: "SG" },
    {
        id: "SK",
        value: "+421",
        country: "Slovakia",
        code: "SK",
        supported: true,
    },
    {
        id: "SI",
        value: "+386",
        country: "Slovenia",
        code: "SI",
        supported: true,
    },
    { id: "SB", value: "+677", country: "Solomon Islands", code: "SB" },
    { id: "SO", value: "+252", country: "Somalia", code: "SO" },
    { id: "ZA", value: "+27", country: "South Africa", code: "ZA" },
    {
        id: "GS",
        value: "+500",
        country: "South Georgia And Sandwich Isl.",
        code: "GS",
    },
    { id: "ES", value: "+34", country: "Spain", code: "ES", supported: true },
    { id: "LK", value: "+94", country: "Sri Lanka", code: "LK" },
    { id: "SD", value: "+249", country: "Sudan", code: "SD" },
    { id: "SR", value: "+597", country: "Suriname", code: "SR" },
    { id: "SJ", value: "+47", country: "Svalbard And Jan Mayen", code: "SJ" },
    { id: "SZ", value: "+268", country: "Swaziland", code: "SZ" },
    { id: "SE", value: "+46", country: "Sweden", code: "SE" },
    { id: "CH", value: "+41", country: "Switzerland", code: "CH" },
    { id: "SY", value: "+963", country: "Syrian Arab Republic", code: "SY" },
    { id: "TW", value: "+886", country: "Taiwan", code: "TW" },
    { id: "TJ", value: "+992", country: "Tajikistan", code: "TJ" },
    { id: "TZ", value: "+255", country: "Tanzania", code: "TZ" },
    { id: "TH", value: "+66", country: "Thailand", code: "TH" },
    { id: "TL", value: "+670", country: "Timor-Leste", code: "TL" },
    { id: "TG", value: "+228", country: "Togo", code: "TG" },
    { id: "TK", value: "+690", country: "Tokelau", code: "TK" },
    { id: "TO", value: "+676", country: "Tonga", code: "TO" },
    { id: "TT", value: "+1 868", country: "Trinidad And Tobago", code: "TT" },
    { id: "TN", value: "+216", country: "Tunisia", code: "TN" },
    { id: "TR", value: "+90", country: "Turkey", code: "TR" },
    { id: "TM", value: "+993", country: "Turkmenistan", code: "TM" },
    {
        id: "TC",
        value: "+1 649",
        country: "Turks And Caicos Islands",
        code: "TC",
    },
    { id: "TV", value: "+688", country: "Tuvalu", code: "TV" },
    { id: "UG", value: "+256", country: "Uganda", code: "UG" },
    { id: "UA", value: "+380", country: "Ukraine", code: "UA" },
    { id: "AE", value: "+971", country: "United Arab Emirates", code: "AE" },
    {
        id: "US",
        value: "+1",
        country: "United States",
        code: "US",
        supported: true,
    },
    { id: "UY", value: "+598", country: "Uruguay", code: "UY" },
    { id: "UZ", value: "+998", country: "Uzbekistan", code: "UZ" },
    { id: "VU", value: "+678", country: "Vanuatu", code: "VU" },
    { id: "VE", value: "+58", country: "Venezuela", code: "VE" },
    { id: "VN", value: "+84", country: "Vietnam", code: "VN" },
    {
        id: "VG",
        value: "+1 284",
        country: "Virgin Islands, British",
        code: "VG",
    },
    { id: "VI", value: "+1 340", country: "Virgin Islands, U.S.", code: "VI" },
    { id: "WF", value: "+681", country: "Wallis And Futuna", code: "WF" },
    { id: "EH", value: "+212", country: "Western Sahara", code: "EH" },
    { id: "YE", value: "+967", country: "Yemen", code: "YE" },
    { id: "ZM", value: "+260", country: "Zambia", code: "ZM" },
    { id: "ZW", value: "+263", country: "Zimbabwe", code: "ZW" },
];

const supportedСountries = countries.filter((country) => country.supported);

export default supportedСountries;
