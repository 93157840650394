/**
 * Builds an Error object from an exception and an optional header.
 * @param {any} exception - The exception to be transformed.
 * @param {string} [header] - Optional header to be used as the error title.
 * @returns {Error} The constructed Error object.
 */
export const buildErrorObject = (exception: any, header?: string): Error => {
    let errorTitle = "";

    if (header) {
        errorTitle = header;
    } else if (exception instanceof Error) {
        errorTitle = exception.name;
    } else {
        errorTitle = "Error";
    }

    const errorBody =
        exception instanceof Error ? exception.message : String(exception);

    const stack = exception instanceof Error ? exception.stack : "";

    const exceptionAsError = new Error(errorBody);
    exceptionAsError.name = errorTitle;
    exceptionAsError.stack = stack;

    return exceptionAsError;
};
