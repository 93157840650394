import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { handleError } from "@/utils";
import IngredientGptService from "@/services/IngredientGpt";
import type {
    Query,
    PaginatedQueryList,
    PaginatedConversationList,
} from "@/types/api/data-contracts";

export const useIngredientGptStore = defineStore("ingredientGptStore", () => {
    const queries = ref<PaginatedQueryList>();
    const conversations = ref<PaginatedConversationList>();
    const queriesAreLoading = ref(false);
    const postingQueryLoader = ref(false);
    const conversationsAreLoading = ref(true);
    const connectionError = ref(false);

    const ingredientGptService = IngredientGptService.getInstance();

    /**
     * returns the conversation uuid of the first conversation
     */
    const activeConversation = computed(
        () => conversations?.value?.results[0]?.conversation_uuid ?? null
    );

    /**
     * fetches list of conversations for the signed in user.
     * creates a new conversation for the user if no conversation exists
     * and if createNewConversation flag is passed
     * @param createNewConversation
     */
    const fetchConversations = async (
        createNewConversation: boolean = false
    ) => {
        try {
            conversationsAreLoading.value = true;
            const response =
                await ingredientGptService.fetchConversationLists();
            conversations.value = response.data;

            /**
             * create a new conversation, if the flag is provided and if no conversations
             * exist for the user
             */
            if (createNewConversation && response.data.results.length === 0) {
                const newConversation =
                    await ingredientGptService.createConversation();
                conversations.value.results.unshift(newConversation.data);
            }
            conversationsAreLoading.value = false;
        } catch (err: any) {
            handleError(err);
            conversationsAreLoading.value = false;
            connectionError.value = true;
        }
    };

    /**
     * fetches the queries for a given conversation
     * @param conversationId
     * @param page
     */
    const fetchConversationQueries = async (
        conversationId: string,
        page?: number
    ) => {
        try {
            queriesAreLoading.value = true;
            const existingQueries = queries.value?.results ?? [];
            const response =
                await ingredientGptService.fetchQueriesFromConversation(
                    conversationId,
                    { page }
                );
            queries.value = {
                ...response.data,
                results: [
                    ...response.data.results.reverse(),
                    ...existingQueries,
                ],
            };
            queriesAreLoading.value = false;
        } catch (err: any) {
            handleError(err);
            queriesAreLoading.value = false;
            connectionError.value = true;
        }
    };

    /**
     * posts user query to BE API
     * @param conversationId
     * @param query
     */
    const postQueryToConversationList = async (
        conversationId: string,
        query: string
    ) => {
        try {
            postingQueryLoader.value = true;
            const response =
                await ingredientGptService.postQueryToConversationList(
                    conversationId,
                    query
                );
            if (queries.value) {
                // save the result in the query so it shows up in the query list instantly
                queries.value.results.push(response.data);
            }
            postingQueryLoader.value = false;
        } catch (err: any) {
            handleError(err);
            postingQueryLoader.value = false;
            connectionError.value = true;
        }
    };

    /**
     * Concatenates new deltas by adding or updating queries
     *
     * we call this function when we receive deltas from websocket
     * this function concatenates the deltas to the response of the latest query
     * @param query
     * @returns
     */
    const addOrReplaceQueryFromSocket = (query: Query) => {
        const existingQueries = queries.value?.results ?? [];

        const existingQueryIndex = existingQueries.findIndex(
            (q) => q.query_uuid === query.query_uuid
        );

        if (existingQueryIndex === -1) {
            // If the query doesn't exist, add it to the array
            return [...existingQueries, query];
        }

        // If the query exists, replace it and concatenate the response
        const updatedQueries = [...existingQueries];
        updatedQueries[existingQueryIndex] = {
            ...query,
            response:
                existingQueries[existingQueryIndex].response + query.response,
        };

        if (queries.value) {
            queries.value.results = updatedQueries;
        }

        return true;
    };

    const $reset = () => {
        queries.value = undefined;
        conversations.value = undefined;
        queriesAreLoading.value = false;
        postingQueryLoader.value = false;
        conversationsAreLoading.value = true;
    };

    return {
        $reset,
        queries,
        conversations,
        queriesAreLoading,
        postingQueryLoader,
        activeConversation,
        fetchConversations,
        conversationsAreLoading,
        connectionError,
        fetchConversationQueries,
        postQueryToConversationList,
        addOrReplaceQueryFromSocket,
    };
});
