import { toRef } from "vue";
import type { AxiosPromise } from "axios";

import type { AppAction } from "@/types";
import {
    ActionClearTriggerEnum,
    type Notification,
} from "@/types/api/data-contracts";

import { useActionsStore } from "@/stores/actions";
import Notifications from "@/services/Notifications";

/**
 * Retrieves the "create first inquiry" action uuid.
 * There is only one notification of this type, and it will be created as soon as the
 * buyer account is created, therefore we don't need to worry about pagination.
 * @param actions
 */
const getCreateInquiryActionUuid = async (
    actions: Notification[] | undefined
) => {
    const clearUpdateIngredientsAction =
        actions &&
        actions.find(
            (action) =>
                action.action_clear_trigger ===
                ActionClearTriggerEnum.PlaceFirstRequest
        );

    return clearUpdateIngredientsAction?.uuid;
};

/**
 * Action to mark the Create First Inquiry Action as clear.
 * It's triggered when a request to create an inquiry is sent
 * and takes the POST AxiosPromise as argument.
 *
 * If the promise resolves without errors, means that the inquiry is created, and we check if
 * the upload action still exists (it has not been cleared).
 *
 * If all these conditions apply, the clear action endpoint is called.
 *
 * @param {Record<createIngredientPromise, AxiosPromise>} args
 * @return {boolean} true in order to continue the loop of actions.
 */
export const clearCreateFirstInquiryAction: AppAction<{
    createIngredientPromise: AxiosPromise;
}> = async (args): Promise<boolean> => {
    const { createIngredientPromise } = args;

    await createIngredientPromise;

    const notificationsService = Notifications.getInstance();
    const actionsStore = useActionsStore();
    const actions = toRef(actionsStore.getDashboardActions);
    const actionUuid = await getCreateInquiryActionUuid(actions.value);

    if (!actionUuid) return true;

    await notificationsService.markActionCleared(actionUuid);

    return true; // Needed to run the next action!
};
