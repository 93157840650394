import type { QuoteCreationFormData } from "@/types";
import type { QuoteDescriptionBlockCreate } from "@/types/api/data-contracts";
import { calculateUnitQuantityPerOrder } from "@/utils";
import { helpers, minValue, required, requiredIf } from "@vuelidate/validators";
import { TimePeriodEnum } from "@/types/TimePeriod";

export const formInitialDataNonRecurring = {
    product_name: "",
    sku: "",
    quantity: null as null | number,
    unit: "",
    priceOption: "",
    price: null,
    currency: "",
    vat_rate: 0 as number,
    vat_percentage: null as unknown as number,
    delivery_date: "",
    payment_term: "30",
    paymentTermsOther: null as unknown as number,
    payment_delay: null as unknown as number,
    shelf_life: null,
    quote_valid_for: null,
    quote_valid_for_unit: TimePeriodEnum.Days,
    country_of_origin: "" as string,
    lead_time: null,
    product_description: "",
    payment_scenario: "",
    agreement_required: true as boolean | string | undefined,
    show_to_other_buyers: false,
    minimum_order_quantity_for_other_buyers: null,
    maximum_order_quantity_for_other_buyers: null,
    packaging_format: "",
    description_blocks: [] as QuoteDescriptionBlockCreate[],
};

export const formInitialData = {
    ...formInitialDataNonRecurring,
    shelf_life: undefined,
    contract_length: null as null | number,
    case_size: null as null | number,
    recurring_order_lead_time: null as null | number,
    agreement_required: true,
    moqOptions: "",
    is_custom_contract: false,
    quantity_per_order: null as null | number,
    description_blocks: [] as QuoteDescriptionBlockCreate[],
};

export const formValidations = (formData: QuoteCreationFormData) => {
    /**
     * Validator for quantity_per_order, check if
     * quantity per order is higher than moq.
     *
     * @param quantityPerOrder
     * @return {boolean}
     */
    const equalOrHigherThanMoq = (quantityPerOrder: number) => {
        const {
            moqOptions: unit,
            quantity: moq,
            is_custom_contract: isCustomContract,
        } = formData;

        if (!isCustomContract) return true;

        if (!quantityPerOrder || !moq) return false;

        const computedQuantityPerOrder = calculateUnitQuantityPerOrder(
            unit,
            quantityPerOrder
        );

        return (computedQuantityPerOrder as number) >= moq;
    };

    return {
        formData: {
            product_name: {
                required,
            },
            quantity: {
                required,
                minimum: minValue(0.00001),
            },
            unit: {
                required,
            },
            priceOption: {},
            price: {
                required,
            },
            vat_percentage: {
                required,
            },
            delivery_date: {
                required,
            },

            payment_term: {
                required,
            },
            paymentTermsOther: {
                condition: requiredIf(formData.payment_term === "other"),
            },
            quote_valid_for: {
                required,
            },
            quote_valid_for_unit: {
                required,
            },
            country_of_origin: {
                required,
            },
            lead_time: {
                required,
            },
            recurring_order_lead_time: {
                required,
            },
            product_description: {
                required,
            },
            packaging_format: { required },
            sku: {},
            contract_length: {
                condition: requiredIf(formData.is_custom_contract),
            },
            case_size: {
                condition: requiredIf(formData.moqOptions === "cases"),
            },
            agreement_required: {
                required,
            },
            moqOptions: {
                required,
            },
            is_custom_contract: {},
            quantity_per_order: {
                condition: requiredIf(formData.is_custom_contract === true),
                equalOrHigherThanMoq: helpers.withMessage(
                    "This field cannot be empty",
                    equalOrHigherThanMoq
                ),
            },
            show_to_other_buyers: {},
            minimum_order_quantity_for_other_buyers: {
                condition: requiredIf(formData.show_to_other_buyers),
            },
            maximum_order_quantity_for_other_buyers: {
                condition: requiredIf(formData.show_to_other_buyers),
            },
            description_blocks: {},
        },
    };
};

export const formValidationsNonRecurring = (
    formData: QuoteCreationFormData
) => ({
    formData: {
        product_name: { required },
        quantity: { required, minimum: minValue(0.00001) },
        unit: { required },
        priceOption: { required },
        price: { required },
        vat_percentage: { required },

        payment_term: { required },
        paymentTermsOther: {
            condition: requiredIf(formData.payment_term === "other"),
        },
        shelf_life: { required },
        quote_valid_for: { required },
        quote_valid_for_unit: {
            required,
        },
        country_of_origin: { required },
        lead_time: { required },
        product_description: { required },
        packaging_format: { required },
        sku: {},
        show_to_other_buyers: {},
        minimum_order_quantity_for_other_buyers: {
            condition: requiredIf(formData.show_to_other_buyers),
        },
        maximum_order_quantity_for_other_buyers: {
            condition: requiredIf(formData.show_to_other_buyers),
        },
        description_blocks: {},
    },
});
