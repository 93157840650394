import { isObjectLike } from "lodash";
import { sentryCaptureException } from "@/utils/error-handling/sentryCaptureException";

import {
    handleArrayError,
    handleObjectError,
    handleStringError,
} from "@/utils";

/**
 * Handles various types of errors by categorizing and processing them appropriately.
 *
 * @param {any} exception - The error to handle. Can be of any type including string, object, or array.
 * @param {boolean} [displayToast=false] - Flag to determine if the error should be displayed to the user.
 * @param {string} [header="Error"] - Header for the toast message. Defaults to "Error".
 * @param {string} [userOnlyMessage] - Optional message to display to the user instead of the error message.
 */
const handleError = (
    exception: any,
    displayToast: boolean = false,
    header: string = "Error",
    userOnlyMessage: string | undefined = undefined
) => {
    if (typeof exception === "string" || exception instanceof String) {
        handleStringError(
            exception.toString(),
            header,
            displayToast,
            userOnlyMessage
        );
    } else if (Array.isArray(exception)) {
        handleArrayError(exception, displayToast, header, handleError);
    } else if (isObjectLike(exception)) {
        handleObjectError(exception, displayToast);
    } else {
        sentryCaptureException(exception, header);
    }
};

export default handleError;

export { handleError };
