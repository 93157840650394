import { memoize } from "lodash";
import type { QuoteOrderDetail } from "@/types/api/data-contracts";

/**
 * Gets the first quote delivery object.
 * @type {((quotes: QuoteOrderDetail[]) => (null | CompanyAddress)) & MemoizedFunction}
 */
export const getMultiQuoteDeliveryAddress = memoize(
    (quotes: QuoteOrderDetail[]) => {
        if (!quotes.length) return null;

        return quotes[0].dispatch_address;
    }
);
