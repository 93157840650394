import { defineStore } from "pinia";
import QuoteService from "@/services/Quote";
import SupplierService from "@/services/Supplier";
import handleError from "@/utils/handleError";
import dayjs from "dayjs";
import type {
    QuoteCreate,
    QuoteDetailDispatch,
    SupplierInquiryResponse,
    SupplierQuoteUpdate,
} from "@/types/api/data-contracts";
import { calculateTimePeriodInDays, isAxiosError } from "@/utils";

const supplierService = SupplierService.getInstance();
const quoteService = QuoteService.getInstance();

export const useQuoteStore = defineStore({
    id: "quoteStore",
    state: () => ({
        loading: false,
        published: false,
        data: {} as unknown as QuoteDetailDispatch,
        inquiry: null as unknown as SupplierInquiryResponse,
    }),
    getters: {
        isRecurringQuote: (state) => state.inquiry?.is_recurring ?? false,
        inquiryCurrency: (state) => state.inquiry?.currency ?? "GBP",
    },
    actions: {
        setData(payload: object) {
            this.data = { ...this.data, ...payload };
        },
        setMOQ(moq: number) {
            this.data.minimum_order_quantity = moq.toString();
            this.data.quantity = moq.toString();
        },
        async fetchInquiryDetails(inquiryId: string) {
            try {
                const response = await supplierService.getInquiryDetails(
                    inquiryId
                );
                this.inquiry = await response.data;
            } catch (err: any) {
                const errors = err?.response?.data ?? null;
                handleError(errors);
            }
        },
        async initQuote(
            inquiryId: string,
            payload: SupplierQuoteUpdate | QuoteCreate
        ): Promise<boolean> {
            try {
                this.loading = true;
                let response = null;
                // Based on published create or update quote
                if (!this.published) {
                    const quoteCreationPayload = {
                        ...payload,
                        unit_price: Number(payload.unit_price).toFixed(2),
                        total_price: Number(payload.total_price).toFixed(2),
                        quote_valid_for: calculateTimePeriodInDays(
                            Number(payload.quote_valid_for),
                            // @ts-ignore
                            payload.quote_valid_for_unit
                        ),
                    };
                    response = await quoteService.CreateQuote(
                        inquiryId,
                        quoteCreationPayload as QuoteCreate
                    );
                } else {
                    const structuredPayloadForOneTimeQuote = {
                        product_name: payload.product_name,
                        quantity: payload.quantity,
                        unit_price: Number(payload.unit_price).toFixed(2),
                        total_price: Number(payload.total_price).toFixed(2),
                        vat_rate: payload.vat_rate,
                        delivery_date: dayjs(payload.delivery_date).format(
                            "YYYY-MM-DD"
                        ),
                        payment_scenario: payload.payment_scenario,
                        payment_delay: payload.payment_delay,
                        shelf_life: payload.shelf_life,
                        quote_valid_for: calculateTimePeriodInDays(
                            Number(payload.quote_valid_for),
                            // @ts-ignore
                            payload.quote_valid_for_unit
                        ),

                        lead_time: payload.lead_time,
                        product_description: payload.product_description,
                        shipping_cost: payload.shipping_cost,
                        currency: payload.currency,
                        // @todo Check if this is needed here, seems that SupplierQuoteUpdate does not contains a property 'status'
                        // @ts-ignore
                        status: payload.status,
                        unit_of_measurement: payload.unit_of_measurement,
                        logo_url: payload.logo_url,
                        dispatch_address: payload.dispatch_address,

                        incoterms: payload.incoterms,
                        is_custom_contract: payload.is_custom_contract,
                    } as unknown as SupplierQuoteUpdate;

                    const structuredPayloadForRecurringQuote = {
                        ...structuredPayloadForOneTimeQuote,
                        agreement_required: this.data.agreement_required,
                        recurring_order_lead_time: this.data.lead_time,
                        contract_length: this.data.contract_length,
                        case_size: this.data.case_size,
                        minimum_order_quantity:
                            this.data.minimum_order_quantity ||
                            this.data.quantity,
                    } as SupplierQuoteUpdate;

                    response = await quoteService.updateQuote(
                        inquiryId,
                        this.data.uuid,
                        this.data.is_recurring
                            ? structuredPayloadForRecurringQuote
                            : structuredPayloadForOneTimeQuote
                    );
                }
                const data = await response.data;
                this.setData(data);
                this.published = true;
                this.loading = false;
                return true;
            } catch (error: any) {
                this.loading = false;
                const displayToast = isAxiosError(error);
                handleError(error, displayToast);
                return false;
            }
        },
        async publishQuote(
            inquiryId: string,
            quoteId: string
        ): Promise<boolean> {
            try {
                this.loading = true;
                await quoteService.publishQuote(inquiryId, quoteId);
                this.loading = false;
                return true;
            } catch (err: any) {
                this.loading = false;
                const errors = err?.response?.data ?? null;
                handleError(errors);
                return false;
            }
        },
    },
});

export default { useQuoteStore };
