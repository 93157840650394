import { sentryCaptureException } from "@/utils/error-handling/sentryCaptureException";

import { showToastMessage } from "@/utils";
import * as copy from "@/copy/globalErrors.json";

/**
 * Wrapper around the handleError function that notifies the user with standard
 * unknown error message.
 * @param {any} error - The error to log on sentry
 * @param displayToast - Whether we should we display the toast message to the user
 */
export const handleUnknownError = (error: any, displayToast = true) => {
    sentryCaptureException(error);

    showToastMessage(
        copy.unknownError.message,
        copy.unknownError.title,
        displayToast
    );
};
