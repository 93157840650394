import { toRef } from "vue";
import type { AxiosPromise } from "axios";

import type { AppAction } from "@/types";
import {
    ActionClearTriggerEnum,
    type Notification,
} from "@/types/api/data-contracts";

import { useActionsStore } from "@/stores/actions";
import IngredientService from "@/services/Ingredient";
import Notifications from "@/services/Notifications";

/**
 * Retrieves the upload ingredients action uuid.
 * There is only one notification of this type, and it will be created as soon as the
 * buyer account is created, therefore we don't need to worry about pagination.
 * @param actions
 */
const getIngredientUploadActionUuid = async (
    actions: Notification[] | undefined
) => {
    const clearUpdateIngredientsAction =
        actions &&
        actions.find(
            (action) =>
                action.action_clear_trigger ===
                ActionClearTriggerEnum.Value5OrMoreIngredientsAreCreated
        );

    return clearUpdateIngredientsAction?.uuid;
};

const getIngredientsCount = async () => {
    const ingredientService = IngredientService.getInstance();
    const { data: ingredientsData } = await ingredientService.getListings();

    return ingredientsData.count || 0;
};

/**
 * Action to mark the Upload Ingredient Action as clear.
 * It's triggered when a request to create an ingredient is sent
 * and takes the POST AxiosPromise as argument.
 *
 * If the promise resolves without errors, means that the ingredient is created, and we check if
 * the upload action still exists (it has not been cleared) and if the user uploaded at least 5 ingredients.
 *
 * If all these conditions apply, the clear action endpoint is called.
 *
 * @param {Record<createIngredientPromise, AxiosPromise>} args
 * @return {boolean} true in order to continue the loop of actions.
 *
 * @todo Add a call to mark related actions as cleared.
 */
export const clearUploadIngredientsAction: AppAction<{
    createIngredientPromise: AxiosPromise;
}> = async (args): Promise<boolean> => {
    const { createIngredientPromise } = args;

    await createIngredientPromise;

    const notificationsService = Notifications.getInstance();
    const actionsStore = useActionsStore();
    const actions = toRef(actionsStore.getDashboardActions);
    const actionUuid = await getIngredientUploadActionUuid(actions.value);

    if (!actionUuid) return true;

    const ingredientsCount = await getIngredientsCount();

    if (ingredientsCount && ingredientsCount >= 5)
        await notificationsService.markActionCleared(actionUuid);

    return true; // Needed to run the next action!
};
