import type { AppError } from "@/types/error-handling/AppError";
import { sentryCaptureException, showToastMessage } from "@/utils";

/**
 * Handles application-specific errors.
 * Displays a toast message and captures the exception using Sentry.
 *
 * @param {AppError} error - The application-specific error to handle.
 */
export const handleAppError = (error: AppError) => {
    showToastMessage(error.message, error.name, error.displayNotification);

    sentryCaptureException(error);
};
