import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type {
    ProductForecast,
    Forecast,
    ProductionRun,
    PaginatedProductForecastList,
    PaginatedForecastList,
    ProductionSchedule,
    ForecastMonth,
    PaginatedProductionScheduleList,
    PatchedProductForecast,
    SourcingRecommendation,
} from "@/types/api/data-contracts";

class ProductForecastService {
    // eslint-disable-next-line no-use-before-define
    private static instance: ProductForecastService;

    public static getInstance(): ProductForecastService {
        if (!ProductForecastService.instance) {
            ProductForecastService.instance = new ProductForecastService();
        }
        return ProductForecastService.instance;
    }

    createForecast(payload: Partial<Forecast>): AxiosPromise<Forecast> {
        const url = `/forecasting/forecast/`;
        return axiosInstance.post(url, payload);
    }

    getForecast(): AxiosPromise<PaginatedForecastList> {
        const url = `/forecasting/forecast/`;
        return axiosInstance.get(url);
    }

    editForecast(
        id: number,
        payload: Partial<Forecast>
    ): AxiosPromise<Forecast> {
        const url = `/forecasting/forecast/${id}/`;
        return axiosInstance.put(url, payload);
    }

    createProductForecast(
        payload: Partial<ProductForecast>
    ): AxiosPromise<ProductForecast> {
        const url = `/forecasting/product_forecast/`;
        return axiosInstance.post(url, payload);
    }

    patchProductForecast(
        productUuid: string,
        payload: PatchedProductForecast
    ): AxiosPromise<ProductForecast> {
        const url = `/forecasting/product_forecast/${productUuid}/`;

        return axiosInstance.patch(url, payload);
    }

    getProductsForecast(): AxiosPromise<PaginatedProductForecastList> {
        const url = `/forecasting/product_forecast/`;
        return axiosInstance.get(url);
    }

    getProductForecastByUUID(uuid: string): AxiosPromise<ProductForecast> {
        const url = `/forecasting/product_forecast/${uuid}/`;
        return axiosInstance.get(url);
    }

    editProductForecastByUUID(
        uuid: string,
        payload: ProductForecast
    ): AxiosPromise<ProductForecast> {
        const url = `/forecasting/product_forecast/${uuid}/`;
        return axiosInstance.put(url, payload);
    }

    deleteProductForecastByUUID(uuid: string): AxiosPromise<void> {
        const url = `/forecasting/product_forecast/${uuid}/`;
        return axiosInstance.delete(url);
    }

    checkForecastProduct(
        payload: string[]
    ): AxiosPromise<{ product_uuids: string[] }> {
        const url = `/forecasting/product_forecast/check-products/`;
        return axiosInstance.post(url, { product_uuids: payload });
    }

    // Production Schedule EndPoints

    getProductionScheduleForecast(
        productUuid: string
    ): AxiosPromise<PaginatedProductionScheduleList> {
        const url = `/forecasting/product_forecast/get-production-schedule/`;
        return axiosInstance.get(url, {
            params: { product__uuid: productUuid },
        });
    }

    generateProductionSchedules(): AxiosPromise<void> {
        const url = `/forecasting/product_forecast/generate-production-schedules/`;
        return axiosInstance.post(url);
    }

    createProductionScheduleForecast(
        uuid: string
    ): AxiosPromise<ProductionSchedule> {
        const url = `/forecasting/product_forecast/${uuid}/generate-production-schedule/`;
        return axiosInstance.post(url);
    }

    editMonthForecastByUUID(
        uuid: string,
        payload: Partial<ForecastMonth>
    ): AxiosPromise<ForecastMonth> {
        const url = `/forecasting/forecast_months/${uuid}/`;
        return axiosInstance.patch(url, payload);
    }

    createProductionRunForecast(
        payload: Partial<ProductionRun>
    ): AxiosPromise<ProductionRun> {
        const url = `/forecasting/production_runs/`;
        return axiosInstance.post(url, payload);
    }

    editProductionRunByUUID(
        uuid: string,
        payload: Partial<ProductionRun>
    ): AxiosPromise<ProductionRun> {
        const url = `/forecasting/production_runs/${uuid}/`;
        return axiosInstance.patch(url, payload);
    }

    deleteProductionRunByUUID(uuid: string): AxiosPromise<void> {
        const url = `/forecasting/production_runs/${uuid}/`;
        return axiosInstance.delete(url);
    }

    getSourcingRecommendations(): AxiosPromise<SourcingRecommendation[]> {
        const url = `/forecasting/product_forecast/get-sourcing-recommendations/`;

        return axiosInstance.get(url);
    }
}

export default ProductForecastService;
