import { uniqueId } from "lodash";
import { computed, ref } from "vue";
import type { BaseEmit, HTMLInputEvent, FileData } from "@/types";

/**
 * A local collection of files available for upload.
 * Important note: This variable is stored on an application level
 * and needs to be emptied when no longer needed using emptyFilesUploadQueue()
 * @todo Support multiple files upload queues (needed if more than one upload field is used in the same context)
 */
const allFiles = ref<FileData[]>([]);

export const useFileUpload = () => {
    const loading = ref(false);
    const uploading = ref(false);

    /**
     * Filters all files and returns only files that have not been yet uploaded.
     */
    const getFilesUploadQueue = computed(() =>
        allFiles.value.filter((file) => file.uploaded === false)
    );

    /**
     * Filters all files and returns only files that are marked as uploaded.
     */
    const getUploadedFiles = computed(() =>
        allFiles.value.filter((file) => file.uploaded === true)
    );

    const getAllFiles = computed(() => allFiles.value);

    /**
     * Setter for filesUploadQueue.
     * @param newFilesFormData
     */
    const setFilesUploadQueue = (newFilesFormData: FileData[]) => {
        allFiles.value = newFilesFormData;
    };

    /**
     * Removes a single file entry from upload queue.
     * @param fileId
     */
    const removeFileFromUploadQueue = (fileId: string) => {
        allFiles.value = allFiles.value.filter((file) => file.uuid !== fileId);
    };

    /**
     * Adds a file entry to the files list.
     * @param file
     * @param fileId
     */
    const addFile = (
        file: File | string,
        fileId: string,
        category: string | undefined = undefined,
        isFilled: boolean | undefined = undefined,
        uploaded: boolean = false
    ) => {
        if (!allFiles.value) return;

        const name = typeof file === "string" ? file : file.name;

        allFiles.value.push({
            uuid: fileId,
            file,
            name,
            uploaded,
            category,
            isFilled,
        });
    };

    /**
     * Add a file marked as not uploaded to be processed in the upload queue.
     * @param file
     * @param fileId
     * @param category
     * @param isFilled
     */
    const addFileToUploadQueue = (
        file: File | string,
        fileId: string,
        category: string | undefined = undefined,
        isFilled: boolean | undefined = undefined
    ) => {
        addFile(file, fileId, category, isFilled, false);
    };

    const emptyFilesUploadQueue = () => {
        allFiles.value = allFiles.value.filter(
            (file) => file.uploaded === false
        );
    };

    /**
     * Resets the allFiles list to an empty array.
     */
    const emptyAllFiles = () => {
        allFiles.value = [];
    };

    const uploadButtonHandler = (
        event: HTMLInputEvent,
        emit: BaseEmit,
        category: string | undefined = undefined,
        isFilled: boolean | undefined = undefined
    ) => {
        const { target } = event;
        const files = target.files as FileList;

        if (!allFiles.value) return;

        addFile(files[0], uniqueId(), category, isFilled);

        emit("document-appended", allFiles.value);
    };

    const markFilesUploaded = () => {
        const updatedFiles = allFiles.value.map((fileData) => ({
            ...fileData,
            uploaded: true,
        }));
        allFiles.value = updatedFiles;
    };

    /**
     * Loops through the files in the upload queue and calls a given file upload function
     * to per form file upload.
     * @param fileUploader
     * @param uuid
     */
    const processUploadQueue = async (
        fileUploader: (uuid: string, filesFormData: FormData) => Promise<any>,
        uuid: string,
        documentVariableName = "documents"
    ) => {
        const uploadPromises = allFiles.value
            .filter((fileData) => !fileData.uploaded)
            .map(async (fileData) => {
                const newFormData = new FormData();

                newFormData.append(documentVariableName, fileData.file);

                if (fileData.category) {
                    newFormData.set("document_category", fileData.category);
                }

                if (fileData.isFilled !== undefined) {
                    newFormData.set("is_filled", fileData.isFilled.toString());
                }

                return fileUploader(uuid, newFormData);
            });

        await Promise.all(uploadPromises);

        markFilesUploaded();
    };

    return {
        loading,
        uploading,
        getUploadedFiles,
        getAllFiles,
        getFilesUploadQueue,
        addFile,
        addFileToUploadQueue,
        uploadButtonHandler,
        setFilesUploadQueue,
        emptyFilesUploadQueue,
        emptyAllFiles,
        removeFileFromUploadQueue,
        processUploadQueue,
    };
};
