const units = [
    {
        value: "g",
        label: "Grams",
        family: "metric_weight",
    },
    {
        value: "kg",
        label: "Kilograms",
        family: "metric_weight",
    },
    {
        value: "tons",
        label: "Metric Tons",
        family: "metric_weight",
    },
    {
        value: "oz",
        label: "Ounces",
        family: "imperial_weight",
    },
    {
        value: "lbs",
        label: "Pounds",
        family: "imperial_weight",
    },
    {
        value: "L",
        label: "Liters",
        family: "metric_volume",
    },
    {
        value: "fl oz",
        label: "Fluid Ounces",
        family: "imperial_volume",
    },
    {
        value: "pt",
        label: "Pints",
        family: "imperial_volume",
    },
    {
        value: "gals",
        label: "Gallons",
        family: "imperial_volume",
    },
    {
        value: "pcs",
        label: "Pieces",
        family: "pieces",
    },
];

export const unitOfMeasurementList = [
    ...units,
    { value: "ml", label: "Millilitres", family: "metric_volume" },
    { value: "mg", label: "Milligrams", family: "metric_weight" },
].sort((a, b) => a.label.localeCompare(b.label));

const sortedUnits = units.sort((a, b) => a.label.localeCompare(b.label));

export default sortedUnits;
