import type { AppAction } from "@/types";
import type { ForecastMonth } from "@/types/api/data-contracts";
import { useProductionSchedule } from "@/composables";

/**
 * Action to reload production schedule when forecasting period is updated.
 * @param args
 */
export const reloadProductionSchedule: AppAction<{
    productUuid: string;
    fetchProductionSchedule: (
        productUuid: string,
        showLoader: boolean
    ) => Promise<ForecastMonth[] | undefined>;
}> = async (args) => {
    const { productUuid } = args;

    const { fetchProductionSchedule } = useProductionSchedule();

    await fetchProductionSchedule(productUuid, true);

    return true;
};
