import dayjs from "dayjs";

type HasExpiryDate = { [key: string]: any; expiry_date?: string | null };

/**
 * Checks if a given quote has expired based on its expiry date.
 * If the expiry date is not provided, the quote is considered
 * expired by default.
 *
 * @param {QuoteDetail} quoteDetail - Details of the quote, including its expiry date.
 * @param {string} quoteDetail.expiry_date - The expiry date of the quote in a format recognized by the Date constructor.
 *                                            If this field is missing or falsy, the quote is considered expired.
 * @returns {boolean} - Returns `true` if the quote is expired (i.e., the expiry date is before the current date),
 *                      or `false` otherwise.
 */
export const isQuoteExpired = (quoteDetail: HasExpiryDate) => {
    if (!quoteDetail.expiry_date) return true;

    // Initialize expiryDate using quoteDetail.expiry_date and add one day
    const expiryDate = dayjs(quoteDetail.expiry_date).endOf("day");

    // Compare with the current date
    return expiryDate.isBefore(dayjs().endOf("day"));
};
