import { isPlainObject } from "lodash";

import { sentryCaptureException } from "@/utils/error-handling/sentryCaptureException";

import {
    tryToParseStringifiedObject,
    showToastMessage,
    handleObjectError,
} from "@/utils";

/**
 * Processes string errors, including attempting to parse them as JSON.
 *
 * @param {string} error - The error string to handle.
 * @param {string} header - Header for the toast message.
 * @param {boolean} displayToast - Flag to determine if the error should be displayed as a toast.
 * @param {string} [userOnlyMessage] - Optional message to display instead of the error message.
 */
export const handleStringError = (
    error: string,
    header: string,
    displayToast: boolean,
    userOnlyMessage?: string
) => {
    const parsedObject = tryToParseStringifiedObject(error);

    if (parsedObject && isPlainObject(parsedObject)) {
        handleObjectError(parsedObject, displayToast);
    } else {
        showToastMessage(userOnlyMessage || error, header, displayToast);
        sentryCaptureException(error, header);
    }
};
