import { uniqueId } from "lodash";
import { computed, ref } from "vue";

import type { ProductForecast } from "@/types/api/data-contracts";
import type { DemandPlanningProduct } from "@/composables/demand-planning/demandPlanning.schema";

import handleError from "@/utils/handleError";
import ProductForecastService from "@/services/ProductForecast";

// products created by users
const products = ref<DemandPlanningProduct[]>([]);
// Products for which user has already created a forecast
const productsForecast = ref<ProductForecast[]>([]);
// UUIDS of those products which are missing some information
const incompleteProductUUIDS = ref<string[]>([]);

const customFrequencyWatcher = ref("");

const forecastPeriod = ref();

const isCreateForecastFormValid = ref(false);
const hasTabLoaded = ref(false);
const productsForecastLoaded = ref(false);
const productsLoaded = ref(false);
const demandPlanningModifiedAt = ref<Date>();

export const useDemandPlanning = () => {
    const productForecastService = ProductForecastService.getInstance();

    const selectedProducts = computed(() =>
        products.value.filter((product) => product.selected)
    );

    const productForecastUUIDS = computed(() =>
        productsForecast.value.map(
            (singleProductForecast) => singleProductForecast.product
        )
    );

    const getProductsForecast = computed(() => productsForecast.value);

    const productsHaveMissingInformation = computed(
        () => incompleteProductUUIDS.value.length > 0
    );

    const getProductForecastByProductUuid = (productUuid: string) =>
        productsForecast.value.find(
            (singleProductForecast) =>
                singleProductForecast.product === productUuid
        );

    const getSelectedProduct = (productUuid: string) =>
        selectedProducts.value.find((product) => product.uuid === productUuid);

    const setProductCustomFrequency = (productUuid: string) => {
        const singleProductForecast =
            getProductForecastByProductUuid(productUuid);

        if (!singleProductForecast) return;

        customFrequencyWatcher.value = uniqueId();

        singleProductForecast.custom_frequency = true;
    };

    const productHasCustomFrequency = (productUuid: string) => {
        const singleProductForecast =
            getProductForecastByProductUuid(productUuid);

        return singleProductForecast
            ? singleProductForecast.custom_frequency
            : false;
    };

    const clearGlobalState = () => {
        products.value = [];
        productsForecast.value = [];
    };

    const hasIncompleteProducts = computed(
        () => !!incompleteProductUUIDS.value.length
    );

    const isProductIncomplete = (productUuid: string) =>
        incompleteProductUUIDS.value.includes(productUuid);

    const removeProductFromForecast = async (productId: string) => {
        if (productForecastUUIDS.value.includes(productId)) {
            try {
                await productForecastService.deleteProductForecastByUUID(
                    productId
                );
            } catch (err) {
                handleError(err);
            }
        }
    };

    const createProductForecast = async (productId: string) => {
        try {
            await productForecastService.createProductForecast({
                product: productId,
            });
        } catch (err) {
            handleError(err);
        }
    };

    /**
     * unselect the product from base-search list and also remove the product from
     * product forecast if saved
     * @param evt
     */
    const removeProduct = (evt: { label: string; index: number }) => {
        products.value.forEach((product) => {
            if (product.title === evt.label) {
                // eslint-disable-next-line no-param-reassign
                product.selected = false;
                // remove the product from product forecast
                removeProductFromForecast(product.uuid);
            }
        });
    };

    /**
     * fetch products from forecast and save their uuids to an array
     * @todo Rename this function, it does not only fetch the uuids but the product forecasts.
     */
    const fetchProductForecastUUIDS = async () => {
        productsForecastLoaded.value = false;
        try {
            const { data } = await productForecastService.getProductsForecast();
            if (data.results) {
                productsForecast.value = data.results;
            }
        } catch (err) {
            handleError(err);
        }
        productsForecastLoaded.value = true;
    };

    const fetchProductForecast = async (productId: string) => {
        try {
            const { data } =
                await productForecastService.getProductForecastByUUID(
                    productId
                );

            return data;
        } catch (err) {
            handleError(err);
            return null;
        }
    };

    const checkSelectedProductsEligibility = async (productIds: string[]) => {
        try {
            const { data } = await productForecastService.checkForecastProduct(
                productIds
            );
            incompleteProductUUIDS.value = data.product_uuids;
        } catch (err) {
            handleError(err);
        }
    };

    return {
        forecastPeriod,
        products,
        removeProduct,
        selectedProducts,
        clearGlobalState,
        fetchProductForecast,
        productForecastUUIDS,
        incompleteProductUUIDS,
        removeProductFromForecast,
        fetchProductForecastUUIDS,
        checkSelectedProductsEligibility,
        hasIncompleteProducts,
        getProductsForecast,
        productsForecast,
        customFrequencyWatcher,
        isCreateForecastFormValid,
        hasTabLoaded,
        productsForecastLoaded,
        productsLoaded,
        productHasCustomFrequency,
        setProductCustomFrequency,
        createProductForecast,
        isProductIncomplete,
        getSelectedProduct,
        getProductForecastByProductUuid,
        productsHaveMissingInformation,
        demandPlanningModifiedAt,
    };
};
