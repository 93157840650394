const formatErrorsForForm = (errors: { [key: string]: string }) =>
    Object.keys(errors).map((key) => {
        const field = key.replace("_", " ");
        const messages = errors[key]
            .toString()
            .replace(/\[|\]/g, "")
            .split(",");
        const label = `${field}`;
        // @ts-ignore
        const value = messages.join("").replaceAll("'", "");

        return { label, value };
    });

export default formatErrorsForForm;
