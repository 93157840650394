import router from "@/router";
import { safeResetStore } from "@/utils";
import UserService from "@/services/User";
import CompanyService from "@/services/Company";
import SupplierService from "@/services/Supplier";
import type { ISigninError } from "@/types/Error";
import { useParagon } from "@/composables/useParagon";
import type IUserInformation from "@/types/UserInformation";
import { abortAllRequests } from "@/services/axiosInstance";
import formatErrorsForForm from "@/utils/formatErrorsForForm";
import { defineStore, getActivePinia, type Store } from "pinia";
import type ICompanyInformation from "@/types/CompanyInformation";
import type {
    CompanyAddress,
    AddressTypeEnum,
} from "@/types/api/data-contracts";
import mixpanel from "mixpanel-browser";

export const useAuthStore = defineStore({
    id: "userAuth",
    state: () => ({
        booting: false,
        pageLoading: false,
        loading: false,
        loaded: false,
        auth: false,
        masqueradeMode: false,
        errors: [] as ISigninError[],
        token: localStorage.getItem("userAuthToken") || null,
        companyData: null as unknown as ICompanyInformation,
        companyDeliveryAddresses: [] as CompanyAddress[],
        companyDispatchAddresses: [] as CompanyAddress[],
        userData: null as unknown as IUserInformation,
        supplierService: SupplierService.getInstance(),
        userService: UserService.getInstance(),
        companyService: CompanyService.getInstance(),
    }),
    getters: {
        isLoggedIn: (state) => state.token !== null,
        isSupplier: (state) => state?.companyData?.company_type === "supplier",
        isBrand: (state) => state?.companyData?.company_type === "brand",
        getReferralCode: (state) => state?.companyData?.referral_code,
        companyAddress: (state) => state?.companyData?.company_addresses,
        getUserUUID: (state) => state?.userData?.uuid,
    },
    actions: {
        /**
         * Fetch canny token and redirect user if `route.query` contains
         * companyID and redirect
         */
        async fetchCannyToken() {
            const { companyID, redirect } = router.currentRoute.value.query;
            if (companyID && redirect) {
                const response = await this.userService.getCannySSOToken();
                const { token } = response.data;
                const redirectUrl = `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${token}&redirect=${redirect}`;
                window.open(redirectUrl, "_blank")?.focus();
            }
        },
        async fetchCompanyAddresses(
            addressType: "delivery_address" | "dispatch_address"
        ) {
            const { data } = await this.companyService.getAddresses(
                1,
                addressType as `${AddressTypeEnum}`
            );

            if (addressType === "delivery_address") {
                this.companyDeliveryAddresses = data.results || [];
            } else if (addressType === "dispatch_address") {
                this.companyDispatchAddresses = data.results || [];
            }
        },
        async fetchDeliveryAddresses() {
            await this.fetchCompanyAddresses("delivery_address");
        },
        async fetchDispatchAddresses() {
            await this.fetchCompanyAddresses("dispatch_address");
        },
        async fetchAddresses() {
            await this.fetchDeliveryAddresses();
            await this.fetchDispatchAddresses();
        },
        async authenticateUser() {
            try {
                this.booting = true;
                // fetch company information
                const response = await this.companyService.getInfo();
                this.companyData = response.data;
                // fetch user information
                const userResponse = await this.userService.getInfo();
                this.userData = userResponse.data;
                // fetch canny token
                await this.fetchCannyToken();
                // authenticate user with Paragon
                const { authenticateParagonUser } = useParagon();
                await authenticateParagonUser();

                // Fetch company addresses
                await this.fetchAddresses();
                // set state in store
                this.auth = true;
                this.loading = false;
                this.booting = false;
                this.loaded = true;
            } catch (err) {
                this.loading = false;
                this.booting = false;
                this.loaded = true;
            }
        },
        setTokenAndAuthenticate(token: string) {
            this.token = token;
            // setting token in local storage
            localStorage.setItem("userAuthToken", this.token);
            this.authenticateUser();
        },
        setMasqueradeModel() {
            this.masqueradeMode = true;
        },
        async signupSupplier(payload: object) {
            try {
                this.loading = true;
                const { data } = await this.supplierService.onBoard(payload);
                const { token: authToken } = data;
                this.token = authToken;
                // setting token in local storage
                localStorage.setItem("userAuthToken", authToken);
            } catch (err: any) {
                this.loading = false;
                const errors = err?.response?.data ?? null;
                if (errors) {
                    this.errors = formatErrorsForForm(errors);
                }
                return;
            }
            // authenticating the user
            // out of try catch to not trigger unnecessary errors from other actions
            this.authenticateUser();
        },
        async signin(payload: object) {
            try {
                this.loading = true;
                const response = await this.userService.signin(payload);
                const { token: authToken } = await response.data;
                this.token = authToken;
                // setting token in local storage
                localStorage.setItem("userAuthToken", authToken);
                // authenticating the user
                this.authenticateUser();
            } catch (err: any) {
                this.loading = false;
                const errors = err?.response?.data ?? [];
                // we need to show the sign in errors inside the form instead of a toast
                if (errors) {
                    this.errors = formatErrorsForForm(errors);
                }
            }
        },
        signout() {
            // Step 1: show app loader
            this.pageLoading = true;

            // Step 2: Reset all active Pinia stores
            const activePinia = getActivePinia();
            if (activePinia) {
                // for some reason Pinia type does not include _s in its type definition
                // @ts-ignore
                activePinia._s.forEach((store: Store) => {
                    safeResetStore(store);
                });
            }
            // Step 3: Abort any ongoing Axios network calls
            abortAllRequests();

            // Step 4: Remove token from local storage
            localStorage.removeItem("userAuthToken");

            // Step 5: Redirect user to sign-in page
            router.push({ name: "Signin" });

            // Step 6: hide app loader
            this.pageLoading = false;

            // Step 7: reset Mixpanel identity
            mixpanel.reset();
        },
    },
    persist: { paths: ["masqueradeMode"] },
});

export default { useAuthStore };
