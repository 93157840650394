import { TimePeriodEnum } from "@/types/TimePeriod";

export const calculateTimePeriodInDays = (value: number, unit: TimePeriodEnum) => {
    switch (unit) {
        case TimePeriodEnum.Days:
            return value;
        case TimePeriodEnum.Months:
            return value * 30;
        case TimePeriodEnum.Years:
            return value * 365;
        default:
            return value;
    }
};

export default calculateTimePeriodInDays;
