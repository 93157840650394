import { useEvents } from "@/composables";
import axiosInstance from "@/services/axiosInstance";
import type { EventName } from "@/types";
import {
    ActionClearTriggerEnum,
    type InquiryResponse,
    type AllAndFeaturedQuotesForInquiry,
    type EmailNotifications,
    type PaginatedDashboardContractsListList,
    type PaginatedDashboardInquiriesList,
    type PaginatedInquiryListList,
    type PaginatedQuoteDetailList,
    type SalesOrderCreate,
} from "@/types/api/data-contracts";
import type { AxiosPromise } from "axios";
import { axiosParamsSerialiser } from "@/services/axiosParamsSerialiser";

class BrandService {
    // eslint-disable-next-line no-use-before-define
    private static instance: BrandService;

    public static getInstance(): BrandService {
        if (!BrandService.instance) {
            BrandService.instance = new BrandService();
        }
        return BrandService.instance;
    }

    async emitEvent(
        eventName: EventName,
        promise: AxiosPromise<any>,
        objectType = "inquiry"
    ) {
        const response = await promise;

        if (eventName === "inquiryCreated") {
            useEvents().emitEvent(eventName, {
                relatedObjectUuid: response.data.uuid,
                relatedObjectType: objectType,
                actionClearTrigger: ActionClearTriggerEnum.PlaceFirstRequest,
            });
        }
    }

    onBoard(payload: object) {
        return axiosInstance.post("companies/onboarding/brand/", payload);
    }

    updateInfo(payload: object, companyId: string) {
        return axiosInstance.put(`companies/brands/${companyId}/`, payload);
    }

    fetchInquiryIcon(payload: { title: string }) {
        return axiosInstance.post("inquiries/icons/", payload);
    }

    createInquiry(payload: object) {
        return axiosInstance.post("inquiries/brand/", payload);
    }

    /**
     * Partially update an inquiry using a PATCH request.
     * @param {string} inquiryId
     * @param {PatchedInquiry} payload
     */
    patchInquiry(inquiryId: string, payload: object) {
        return axiosInstance.patch(`inquiries/brand/${inquiryId}/`, payload);
    }

    /**
     * Full update an inquiry using PUT request.
     * @param {string} inquiryId
     * @param {InquiryUpdate} payload
     */
    updateInquiry(inquiryId: string, payload: object) {
        return axiosInstance.put(`inquiries/brand/${inquiryId}/`, payload);
    }

    deleteInquiry(inquiryId: string) {
        return axiosInstance.delete(`inquiries/brand/${inquiryId}/`);
    }

    publishInquiry(inquiryId: string) {
        const createInquiryPromise = axiosInstance.post(
            `inquiries/brand/${inquiryId}/publish/`
        );

        this.emitEvent("inquiryCreated", createInquiryPromise);

        return createInquiryPromise;
    }

    uploadInquiryDocument(inquiryId: string, formData: FormData) {
        return axiosInstance.post(
            `inquiries/brand/${inquiryId}/upload/`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    }

    getInquiryDocuments(inquiryId: string) {
        return axiosInstance.get(`inquiries/brand/${inquiryId}/documents/`);
    }

    deleteInquiryDocument(inquiryId: string, documentId: string) {
        return axiosInstance.delete(
            `inquiries/${inquiryId}/documents/${documentId}/`
        );
    }

    getInquiries(
        filter?: {
            state?: string | string[] | null;
            name?: string | null;
            category?: string | null;
            page?: number | undefined;
            active_inquiries?: boolean;
        },
        ordering?: string | null
    ): AxiosPromise<PaginatedInquiryListList> {
        return axiosInstance.get("inquiries/brand/", {
            params: {
                ...filter,
                ordering,
            },
            paramsSerializer: axiosParamsSerialiser,
        });
    }

    getInquiryQuotes(
        inquiryId: string,
        filter?: {
            status: string | null;
            // eslint-disable-next-line camelcase
            product_name: string | null;
            page: number | undefined;
        },
        ordering?: string | null
    ): AxiosPromise<PaginatedQuoteDetailList> {
        const url = `inquiries/${inquiryId}/quotes/brand/`;

        return axiosInstance.get(url, {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    getInquiryAllQuotes(
        inquiryId: string
    ): AxiosPromise<AllAndFeaturedQuotesForInquiry[]> {
        return axiosInstance.get(`inquiries/brand/${inquiryId}/all-quotes/`);
    }

    getInquiryFeaturedQuotes(
        inquiryId: string
    ): AxiosPromise<AllAndFeaturedQuotesForInquiry[]> {
        return axiosInstance.get(
            `inquiries/brand/${inquiryId}/featured-quotes/`
        );
    }

    /**
     * Retrieves a inquiry data by inquiry uuid.
     * @deprecated since version 1.59.0 - Use {@link getInquiryDetail} instead.
     * Deprecated because does not specify return type.
     *
     * @param {string} inquiryId
     */
    getInquiryDetails(inquiryId: string) {
        return axiosInstance.get(`inquiries/brand/${inquiryId}/`);
    }

    /**
     * Retrieves a inquiry data by inquiry uuid.
     *
     * @param {string} inquiryId
     */
    getInquiryDetail(inquiryId: string): AxiosPromise<InquiryResponse> {
        return axiosInstance.get(`inquiries/brand/${inquiryId}/`);
    }

    getDashboardInquiries(
        page: number
    ): AxiosPromise<PaginatedDashboardInquiriesList> {
        const url = "/inquiries/brand/dashboard-inquiries/";
        return axiosInstance.get(url, {
            params: {
                page,
            },
        });
    }

    getDashboardContracts(
        page: number
    ): AxiosPromise<PaginatedDashboardContractsListList> {
        const url = "/inquiries/quotes/dashboard-contracts/";
        return axiosInstance.get(url, {
            params: {
                page,
            },
        });
    }

    getOrders() {
        return axiosInstance.get("orders/brand/");
    }

    getOrderDetails(orderId: string) {
        return axiosInstance.get(`orders/brand/${orderId}/`);
    }

    createOrder(quoteId: string[]) {
        const url = "orders/brand/";
        const body = { quotes: quoteId };
        return axiosInstance.post(url, body);
    }

    updateOrderStatus(
        orderId: string,
        payMethod: string,
        payScenario: string,
        selectedCurrency?: string | null
    ) {
        const url = "rapyd/update_order_status_after_payment/";
        const body = {
            order_id: orderId,
            payment_method: payMethod,
            payment_scenario: payScenario,
            selected_currency: selectedCurrency,
        };

        return axiosInstance.post(url, body);
    }

    saveCustomerCard(
        fingerprintToken: string,
        paymentId: string,
        customerToken: string | null,
        quoteId: string | null,
        orderId: string | null
    ) {
        /* eslint-disable camelcase */
        type payload = {
            fingerprint_token: string;
            payment_id: string;
            customer_token: string;
            quote_id: string;
            order_id: string;
        };
        /* eslint-enable camelcase */

        const url = "rapyd/save_customer_card/";
        const body = {} as payload;

        if (fingerprintToken) {
            body.fingerprint_token = fingerprintToken;
        }
        if (paymentId) {
            body.payment_id = paymentId;
        }
        if (customerToken) {
            body.customer_token = customerToken;
        }
        if (quoteId) {
            body.quote_id = quoteId;
        }
        if (orderId) {
            body.order_id = orderId;
        }

        return axiosInstance.post(url, body);
    }

    getEmailPreferences() {
        const url = `/notifications/emails/brand/`;
        return axiosInstance.get(url);
    }

    updateEmailGroupsPreference(
        type: string,
        value: boolean
    ): AxiosPromise<EmailNotifications> {
        const url = `/notifications/emails/brand/on-off-unsubscribe-groups/`;
        return axiosInstance.put(url, {
            type,
            is_activated_for_user: value,
        });
    }

    updateEmailPreference(id: number, value: boolean) {
        const url = `/notifications/emails/brand/${id}/`;
        return axiosInstance.put(url, {
            is_activated_for_user: value,
        });
    }

    unSubscribeEmails(value: boolean) {
        const url = "/notifications/emails/brand/all/";
        return axiosInstance.post(url, {
            all_emails: value,
        });
    }

    createSalesOrder(
        payload: SalesOrderCreate
    ): AxiosPromise<SalesOrderCreate> {
        const url = "/orders/brand/create-sales-order/";
        return axiosInstance.post(url, payload);
    }

    editSalesOrder(
        payload: SalesOrderCreate,
        orderId: string
    ): AxiosPromise<SalesOrderCreate> {
        const url = `/orders/brand/${orderId}/update-sales-order/`;
        return axiosInstance.put(url, payload);
    }
}

export default BrandService;
