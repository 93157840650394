import axiosInstance from "@/services/axiosInstance";
import type {
    PaginatedQuoteNegotiationList,
    QuoteDetailDispatch,
    SupplierQuoteUpdate,
    RapydCheckoutData,
    DuplicateQuote,
    QuoteDetail,
    QuoteRetrieve,
} from "@/types/api/data-contracts";
import type { IQuoteSamples } from "@/types/Quote";
import type { QuoteNegotiationPayloadType } from "@/stores/quoteNegotiation";
import type { AxiosPromise } from "axios";

class QuoteService {
    // eslint-disable-next-line no-use-before-define
    private static instance: QuoteService;

    public static getInstance(): QuoteService {
        if (!QuoteService.instance) {
            QuoteService.instance = new QuoteService();
        }
        return QuoteService.instance;
    }

    CreateQuote(
        inquiryId: string,
        payload: object
    ): AxiosPromise<QuoteDetailDispatch> {
        return axiosInstance.post(
            `inquiries/${inquiryId}/quotes/supplier/`,
            payload
        );
    }

    updateQuote(
        inquiryId: string,
        quoteId: string,
        payload: SupplierQuoteUpdate | object
    ) {
        return axiosInstance.patch(
            `inquiries/${inquiryId}/quotes/supplier/${quoteId}/`,
            payload
        ) as AxiosPromise<QuoteDetailDispatch>;
    }

    duplicateQuote(quoteUuid: string, payload: DuplicateQuote) {
        return axiosInstance.post(
            `inquiries/quotes/${quoteUuid}/duplicate/`,
            payload
        ) as AxiosPromise<QuoteDetail>;
    }

    updateExtensionState(
        inquiryId: string,
        quoteId: string,
        payload: { extension_accepted: boolean | null }
    ) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/`;
        return axiosInstance.put(url, payload);
    }

    updateQuoteForSamples(
        inquiryId: string,
        quoteId: string,
        payload: IQuoteSamples
    ) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/`;
        return axiosInstance.put(url, payload);
    }

    partialUpdateQuote(inquiryId: string, quoteId: string, payload: any) {
        const url = `/inquiries/${inquiryId}/quotes/supplier/${quoteId}/`;
        return axiosInstance.patch(url, payload);
    }

    publishQuote(inquiryId: string, quoteId: string) {
        return axiosInstance.post(
            `inquiries/${inquiryId}/quotes/supplier/${quoteId}/publish/`
        );
    }

    uploadDocument(quoteId: string, payload: object): AxiosPromise<void> {
        return axiosInstance.post(
            `inquiries/quotes/${quoteId}/documents/`,
            payload
        );
    }

    deleteDocument(quoteId: string, documentId: string) {
        const url = `/inquiries/quotes/${quoteId}/documents/${documentId}/`;
        return axiosInstance.delete(url);
    }

    /**
     * Retrieves documents for a quote.
     * Used a generic for response value to be able to use the proper response type
     * on new usage of it but without breaking old instances.
     * @param {string} quoteId
     * @return {AxiosPromise<PaginatedQuoteDocumentList>} - Paginated list of documents
     */
    getDocuments<T = any>(quoteId: string): AxiosPromise<T> {
        return axiosInstance.get(`inquiries/quotes/${quoteId}/documents/`);
    }

    getListings(
        filter?: {
            status?: string | null;
            // eslint-disable-next-line camelcase
            product_name?: string | null;
            page?: number | undefined;
            // eslint-disable-next-line camelcase
            inquiry_uuid?: string | null;
            category?: string | null;
            contract_status?: string | null;
        },
        ordering?: string | null
    ) {
        return axiosInstance.get("inquiries/quotes/", {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    /**
     * Retrieves a quote data by quote uuid.
     * @deprecated since version 1.45.0 - Use {@link getQuoteDetails} instead.
     * Deprecated because does not specify return type.
     *
     * @param {string} quoteId
     */
    getDetails(quoteId: string) {
        return axiosInstance.get(`inquiries/quotes/${quoteId}/`);
    }

    /**
     * Fetches quote details by ID.
     * @param {string} quoteId - The unique quote database identifier.
     * @return {AxiosPromise<QuoteRetrieve>}
     */
    getQuoteDetails(quoteId: string): AxiosPromise<QuoteRetrieve> {
        return axiosInstance.get(`inquiries/quotes/${quoteId}/`);
    }

    getContractOrderDetails(inquiryId: string, quoteId: string) {
        return axiosInstance.get(
            `inquiries/${inquiryId}/quotes/brand/${quoteId}/`
        );
    }

    getCheckoutId(
        orderId: string,
        selectedCurrency: string
    ): AxiosPromise<RapydCheckoutData> {
        const body = { order_id: orderId, selected_currency: selectedCurrency };

        return axiosInstance.post("rapyd/get_checkout_id/", body);
    }

    getSaveCardId(quoteId: string, selectedCurrency: string) {
        const body = { quote_id: quoteId, selected_currency: selectedCurrency };

        return axiosInstance.post(
            "rapyd/get_checkout_id_supplier_terms_pay_by_card/",
            body
        );
    }

    decline(inquiryId: string, quoteId: string, payload: object) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/decline/`;
        return axiosInstance.post(url, payload);
    }

    markQuoteAsContractCreated(inquiryId: string, quoteId: string) {
        const url = `/inquiries/${inquiryId}/quotes/supplier/${quoteId}/mark-contract-created/`;
        return axiosInstance.post(url);
    }

    markQuoteAsProceeded(inquiryId: string, quoteId: string) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/mark-proceeded/`;
        return axiosInstance.post(url);
    }

    markQuoteAsContractConfirmed(inquiryId: string, quoteId: string) {
        const url = `/inquiries/${inquiryId}/quotes/supplier/${quoteId}/mark-contract-confirmed/`;
        return axiosInstance.post(url);
    }

    markContractAsProceeded(inquiryId: string, quoteId: string) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/mark-contract-proceeded/`;
        return axiosInstance.post(url);
    }

    buyerPartialUpdateQuote(inquiryId: string, quoteId: string, payload: any) {
        const url = `/inquiries/${inquiryId}/quotes/brand/${quoteId}/`;
        return axiosInstance.patch(url, payload);
    }

    getDetailsWithSupplier(inquiryId: string, quoteId: string) {
        return axiosInstance.get(
            `inquiries/${inquiryId}/quotes/supplier/${quoteId}/`
        );
    }

    createQuoteNegotiation(
        quoteId: string,
        payload: QuoteNegotiationPayloadType
    ): AxiosPromise<void> {
        const url = `/inquiries/quotes/${quoteId}/negotiations/`;
        return axiosInstance.post(url, {
            quote_uuid: quoteId,
            ...payload,
        });
    }

    getQuoteNegotiations(
        quoteId: string
    ): AxiosPromise<PaginatedQuoteNegotiationList> {
        const url = `/inquiries/quotes/${quoteId}/negotiations/`;
        return axiosInstance.get(url);
    }

    rejectQuoteNegotiations(quoteId: string, uuid: string): AxiosPromise<void> {
        const url = `/inquiries/quotes/${quoteId}/negotiations/${uuid}/reject/`;
        return axiosInstance.post(url);
    }

    acceptQuoteNegotiations(quoteId: string, uuid: string): AxiosPromise<void> {
        const url = `/inquiries/quotes/${quoteId}/negotiations/${uuid}/accept/`;
        return axiosInstance.post(url);
    }

    getQuotesDetailsBrand(inquiryId: string, quoteId: string) {
        const url = `inquiries/${inquiryId}/quotes/brand/${quoteId}/`;
        return axiosInstance.get(url);
    }

    requestQuoteUpdate(inquiryId: string, quoteId: string) {
        const url = `inquiries/${inquiryId}/quotes/brand/${quoteId}/request-update/`;
        return axiosInstance.post(url);
    }
}

export default QuoteService;
