import axiosInstance from "@/services/axiosInstance";
import type {
    payoutCompanyTypePayload,
    beneficiaryDetailsPayload,
} from "@/types/KybPayout";

class RapydService {
    // eslint-disable-next-line no-use-before-define
    private static instance: RapydService;

    public static getInstance(): RapydService {
        if (!RapydService.instance) {
            RapydService.instance = new RapydService();
        }
        return RapydService.instance;
    }

    getPayoutStatus() {
        const url = "/rapyd/payout/status/";
        return axiosInstance.get(url);
    }

    setPayoutCompanyType(payload: payoutCompanyTypePayload) {
        const url = "/rapyd/payout/set_company_type/";
        return axiosInstance.post(url, payload);
    }

    getBeneficiaryId() {
        const url = "/rapyd/payout/get_redirect_id/";
        return axiosInstance.post(url);
    }

    saveBeneficiaryDetails(payload: beneficiaryDetailsPayload) {
        const url = "/rapyd/payout/callback/";
        return axiosInstance.post(url, payload);
    }
}

export default RapydService;
