import axiosInstance from "@/services/axiosInstance";

class MySuppliers {
    // eslint-disable-next-line no-use-before-define
    private static instance: MySuppliers;

    public static getInstance(): MySuppliers {
        if (!MySuppliers.instance) {
            MySuppliers.instance = new MySuppliers();
        }
        return MySuppliers.instance;
    }

    listSuppliers(brandId: string, filter?: { page?: number | null }) {
        const url = `/companies/brands/${brandId}/my-suppliers/`;
        return axiosInstance.get(url, { params: { ...filter } });
    }

    getDetails(brandId: string, companyId: string) {
        const url = `/companies/brands/${brandId}/my-suppliers/${companyId}/`;
        return axiosInstance.get(url);
    }
}

export default MySuppliers;
