/**
 * Handles errors when the exception is an array by processing each element.
 *
 * @param {any[]} errors - The array of errors to handle.
 * @param {boolean} displayToast - Flag to determine if the error should be displayed as a toast.
 * @param {string} header - Header for the toast message.
 * @param {Function} handleError - Reference to the handleError function for recursive calls.
 */
export const handleArrayError = (
    errors: any[],
    displayToast: boolean,
    header: string,
    handleError: Function
) => {
    errors.forEach((error) => handleError(error, displayToast, header));
};
