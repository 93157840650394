import type { AxiosPromise } from "axios";
import axiosInstance from "@/services/axiosInstance";
import type {
    Query,
    Conversation,
    PaginatedQueryList,
    PaginatedConversationList,
} from "@/types/api/data-contracts";

class IngredientGptService {
    // eslint-disable-next-line no-use-before-define
    private static instance: IngredientGptService;

    public static getInstance(): IngredientGptService {
        if (!IngredientGptService.instance) {
            IngredientGptService.instance = new IngredientGptService();
        }
        return IngredientGptService.instance;
    }

    /**
     * returns the conversation list for the signed in company
     * @param filters
     * @returns `PaginatedConversationList`
     */
    fetchConversationLists(filters?: {
        page: number | null | undefined;
    }): AxiosPromise<PaginatedConversationList> {
        const url = "/gpt/conversation/";
        return axiosInstance.get(url, { params: { ...filters } });
    }

    /**
     * creates a new conversation for the signed in company
     */
    createConversation(): AxiosPromise<Conversation> {
        const url = "/gpt/conversation/";
        return axiosInstance.post(url);
    }

    /**
     * returns paginated list of queries for a given conversation
     * @param conversationId
     * @param filters
     * @returns `PaginatedQueryList`
     */
    fetchQueriesFromConversation(
        conversationId: string,
        filters?: {
            page: number | null | undefined;
        }
    ): AxiosPromise<PaginatedQueryList> {
        const url = `/gpt/conversation/${conversationId}/queries/`;
        return axiosInstance.get(url, { params: { ...filters } });
    }

    /**
     * posts a new query in the conversation list
     * @param conversationId
     * @param query
     * @returns `Query`
     */
    postQueryToConversationList(
        conversationId: string,
        query: string
    ): AxiosPromise<Query> {
        const url = `/gpt/conversation/${conversationId}/queries/`;
        return axiosInstance.post(url, { query });
    }
}

export default IngredientGptService;
