import { defineStore } from "pinia";

export const useToastStore = defineStore("useToastStore", {
    state: () => ({
        severity: "",
        summary: "",
        detail: "",
        life: 5000,
    }),
});
