import { uniqueId } from "lodash";
import { handleError } from "@/utils";
import type { AxiosPromise } from "axios";
import FilesService from "@/services/Files";
import { uploadEventBus } from "@/buses/uploadEventBus";
import resumableUploadWorker from "@/workers/resumableUploadWorkerInstance";
import type { GoogleUploadFieldName, GoogleUploadModelName } from "@/types";

const fileService = FilesService.getInstance();

/**
 * Composable for handling Google resumable uploads
 *
 */
export const useGoogleUpload = () => {
    /**
     * Creates a resumable upload session for a single file
     *
     * @param {File} file - The file to be uploaded
     * @returns {Promise<Object>} Session data combined with the original file
     *   - token: string - Authentication token for the upload
     *   - url: string - Signed URL for the upload
     *   - file: File - Original file object
     * @private
     */
    const createUploadSession = async (
        file: File,
        modelName: GoogleUploadModelName,
        fieldName: GoogleUploadFieldName
    ) => {
        const { name: fileName, size, type: contentType } = file;

        const { data } = await fileService.createResumableUploadSession({
            model_name: modelName,
            field_name: fieldName,
            file_name: fileName,
            size,
            content_type: contentType,
        });

        return { ...data, file };
    };

    /**
     * Initiates the upload process by sending file data to the Web Worker
     *
     * @param {Worker} worker - The Web Worker instance handling the upload
     * @param {File} file - The file to be uploaded
     * @param {string} token - Authentication token for the upload
     * @param {string} signedUrl - The signed URL where the file should be uploaded
     * @private
     */
    const startUploadProcess = async (
        file: File,
        token: string,
        signedUrl: string,
        modelId: string,
        params: object,
        callback: (modeId: string, payload: object) => AxiosPromise<any>
    ) => {
        // create a callback id
        const callbackId = uniqueId(file?.name ?? "File");
        // Register the callback with the event bus
        uploadEventBus.addCallback(callbackId, callback);

        resumableUploadWorker.postMessage({
            sessionUri: signedUrl,
            token,
            file,
            modelId,
            callbackId,
            params,
        });
    };

    /**
     * Handles the upload of multiple documents
     *
     * @param {File[]} documents - Array of files to be uploaded
     * @returns {Promise<void>}
     * @throws Will throw an error if the upload process fails
     */
    const uploadDocuments = async (
        documents: File[],
        modelName: GoogleUploadModelName,
        fieldName: GoogleUploadFieldName,
        modelId: string,
        callback: (modeId: string, payload: object) => AxiosPromise<any>,
        params = {}
    ) => {
        try {
            // Create upload session for each file.
            const uploadSessionsRequests = Array.from(documents).map(
                (file: File) => createUploadSession(file, modelName, fieldName)
            );

            // Wait for all upload sessions to be created.
            const uploadSessions = await Promise.all(uploadSessionsRequests);

            // Start the upload process for each session.
            uploadSessions.forEach((session) => {
                startUploadProcess(
                    session.file,
                    session.token,
                    session.url,
                    modelId,
                    params,
                    callback
                );
            });
        } catch (error) {
            uploadEventBus.removeCallback(modelId);
            handleError(error);
        }
    };

    return {
        uploadDocuments,
        resumableUploadWorker,
    };
};
