import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/userAuth";
import ChatService from "@/services/Chat";
import type { Channel } from "@/types/api/data-contracts";
import type { IMessageData } from "@/types/Notification";

export const useChatListStore = defineStore("chatListStore", () => {
    const authStore = useAuthStore();
    const chatService = ChatService.getInstance();

    const chatList = ref<Channel[]>([]);

    const hasLoadMoreButton = ref(false);

    const fetchMoreLoading = ref(false);

    const currentPage = ref(1);

    const countUnreadMessages = ref();

    const chatListHasLoaded = ref(false);

    const fetchUserChatList = async () => {
        fetchMoreLoading.value = true;
        const { data } = await chatService.getUserChatList(currentPage.value);
        chatList.value = [...chatList.value, ...(data?.results ?? [])];
        hasLoadMoreButton.value = !!data.next;

        countUnreadMessages.value = data?.unread_count;
        fetchMoreLoading.value = false;
        chatListHasLoaded.value = true;
    };

    /**
     * Increases count of unread messages by 1.
     * @param {ISocketEvent} message
     */
    const increaseUnreadCount = (message: IMessageData) => {
        if (message.sender?.uuid === authStore.getUserUUID) return;
        countUnreadMessages.value += 1;
    };

    /**
     * @param data
     * this function is called when a new message is received via web socket
     */
    const appendDataToChatList = (data: IMessageData) => {
        const structuredPayload = {
            uuid: data.channel.uuid,
            new_messages_check: data.channel.new_messages_check,
            companies: data.channel.companies,
            related_object_type: data.channel.related_object_type,
            related_object_uuid: data.channel.related_object_uuid,
            latest_message: {
                text: data.text,
                attachment: data.attachment,
                sender: data.sender,
                is_read: data.is_read,
                created: data.created,
                modified: data.modified,
                channel: data.channel,
                supplier_name: data.buyer_name,
                buyer_name: data.supplier_name,
            },
            object_history: [],
            supplier_name: data.buyer_name,
            buyer_name: data.supplier_name,
        };
        const filteredList = chatList.value.filter(
            (item) => item.uuid !== structuredPayload.uuid
        );
        chatList.value = filteredList;
        // @ts-ignore
        chatList.value.unshift(structuredPayload);
    };

    /**
     * Finds a channel by its id in the store chat list.
     * @param {string} channelId - The channel ID
     *
     * @return {Channel} - The found channel.
     */
    const getChannelById = (channelId: string) =>
        chatList.value.find((chatItem) => chatItem.uuid === channelId);

    /**
     * Given a channel object or a channel ID, if the given parameter is a string
     * retrieves the channel object from the store chat list,
     * if the give object is a Channel, returns the same Channel
     * @param channelIdOrChannel
     *
     * @return {Channel}
     */
    const getChannel = (channelIdOrChannel: Channel | string) => {
        if (typeof channelIdOrChannel === "string")
            return getChannelById(channelIdOrChannel);

        return channelIdOrChannel;
    };

    const markChannelAsRead = async (channelIdOrChannel: Channel | string) => {
        const channel = getChannel(channelIdOrChannel);

        if (!channel || channel?.latest_message?.is_read) return;

        const filteredList = chatList.value.map((item) => {
            if (item.uuid === channel.uuid) {
                return {
                    ...item,
                    latest_message: {
                        ...item.latest_message,
                        is_read: "true",
                    },
                };
            }

            return item;
        });

        chatList.value = filteredList;

        const { data } = await chatService.markChannelLatestMessageAsRead(
            channel.uuid
        );
        countUnreadMessages.value = data?.unread_count;
    };

    const $reset = () => {
        chatList.value = [];
        hasLoadMoreButton.value = false;
    };

    watch(currentPage, () => {
        fetchUserChatList();
    });

    return {
        countUnreadMessages,
        currentPage,
        fetchMoreLoading,
        chatList,
        hasLoadMoreButton,
        chatListHasLoaded,
        $reset,
        fetchUserChatList,
        markChannelAsRead,
        appendDataToChatList,
        increaseUnreadCount,
    };
});
