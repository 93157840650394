import router from "@/router";
import { useAuthStore } from "@/stores/userAuth";
import handleError from "@/utils/handleError";
import type {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
} from "axios";
import axios from "axios";

// #region Utils

/**
 * Loops through request handlers and applies them to the Axios Instance.
 * @param axiosInstance
 * @param requestInterceptors
 */
export const applyRequestInterceptors = (
    axiosInstance: AxiosInstance,
    abortControllers: AbortController[],
    requestInterceptors: ((config: AxiosRequestConfig) => void)[]
) => {
    requestInterceptors.forEach((interceptor) => {
        // @ts-ignore
        axiosInstance.interceptors.request.use(interceptor);
        axiosInstance.interceptors.request.use((request) => {
            const abortController = new AbortController();
            request.signal = abortController.signal;
            abortControllers.push(abortController);
            return request;
        });
    });
};

/**
 * Loops through the error handlers and applies them to the Axios Instance.
 * @param axiosInstance
 * @param requestErrorHandlers
 */
export const applyResponseErrorHandlers = (
    axiosInstance: AxiosInstance,
    requestErrorHandlers: ((
        error: AxiosError<{ detail: string; error_code: string }>
    ) => void)[]
) => {
    requestErrorHandlers.forEach((errorHandler) => {
        axiosInstance.interceptors.response.use(
            (response) => response,
            errorHandler
        );
    });
};
// #endregion Utils

// #region Request Interceptors
/**
 * Send authorisation header on request.
 * @param config
 */
export const sendAuthorisationHeader = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("userAuthToken");

    const headers = config.headers as AxiosRequestHeaders;

    headers.Authorization = token ? `Token ${token}` : "";

    return config;
};

// #endregion Request Interceptors

// #region Response Error Handlers

/**
 * Logs out if token is not valid.
 * @param error
 */
export const handleInvalidToken = (
    error: AxiosError<{ detail: string; error_code: string }>
) => {
    const authStore = useAuthStore();

    if (error.response?.data?.detail === "Invalid token") {
        authStore.signout();
    }

    throw error;
};

/**
 * Redirects to coming soon page if a country is not supported.
 * @param error
 */
export const handleUnsupportedCountry = (
    error: AxiosError<{ detail: string; error_code: string }>
) => {
    if (error?.response?.data?.error_code === "unsupported_country") {
        const authStore = useAuthStore();

        authStore.signout();

        router.push("/signup/coming-soon");
    }

    throw error;
};

/**
 * Redirects to 404 page if route is not found.
 * @param error
 */
export const handleNotFound = (
    error: AxiosError<{ detail: string; error_code: string }>
) => {
    if (error?.response?.status === 404) {
        router.replace("/404");
    }

    throw error;
};

export const handleApiCallErrors = (
    error: AxiosError<{ detail: string; error_code: string }>
) => {
    if (!error?.response?.status) return;

    if (error?.response?.status >= 400 && error?.response?.status < 500) {
        console.warn(error.response?.data.detail);
        return;
    }

    handleError(error.response?.statusText || error);
};

/**
 * Logs to cancel if request was cancelled
 * @param error
 */
export const handleRequestAbortion = (error: any) => {
    if (axios.isAxiosError(error)) {
        console.error("Request Aborted!");
    }
};

// #endregion Response Error Handlers
