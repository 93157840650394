import KYBService from "@/services/Kyb";
import type { MorKYBDetails } from "@/types/api/data-contracts";
import { handleError } from "@/utils";
import { ref } from "vue";

const morKybStatuses = ref<MorKYBDetails>();

export const useKybStatus = () => {
    const kybService = KYBService.getInstance();

    const fetchKybStatus = async () => {
        try {
            const { data } = await kybService.getMorKybStatus();
            morKybStatuses.value = data;
        } catch (err) {
            handleError(err);
        }
    };

    return {
        morKybStatuses,
        fetchKybStatus,
    };
};
