import { useDemandPlanning } from "@/composables/demand-planning/useDemandPlanning";
import { computed, ref, watch } from "vue";
import ProductForecastService from "@/services/ProductForecast";
import type { ForecastMonth, ProductionRun } from "@/types/api/data-contracts";
import handleError from "@/utils/handleError";

const productionSchedule = ref<ForecastMonth[] | undefined>([]);
const productionScheduleModifiedAt = ref<Date | undefined>();
const loading = ref(false);
const activeProductId = ref("");
const productionScheduleExpanded = ref(false);
const productionScheduleDisabled = ref(true);
const firstProductionRun = ref<Date | undefined>();
const productionScheduleHasBeenModified = ref(false);
const productForecastSettingsHaveBeenModified = ref(false);
const scheduleTypeModified = ref(false);

export const useProductionSchedule = () => {
    const productionScheduleService = ProductForecastService.getInstance();
    const productionRunByUUID = ref<ProductionRun[]>([]);
    const forecastMonthByUUID = ref<ForecastMonth[]>([]);
    const productionRun = ref<ProductionRun[]>([]);

    const {
        productHasCustomFrequency,
        setProductCustomFrequency,
        selectedProducts,
    } = useDemandPlanning();

    const productionScheduleIsGenerated = computed(
        () => productionSchedule.value && productionSchedule.value.length
    );

    const activeProduct = computed(() => {
        if (!activeProductId.value) return undefined;

        return selectedProducts.value.find(
            (product) => product.uuid === activeProductId.value
        );
    });

    const getCustomProductFrequency = (productUuid: string) =>
        productHasCustomFrequency(productUuid);

    const setCustomFrequency = async (productUuid: string) => {
        await productionScheduleService.patchProductForecast(productUuid, {
            custom_frequency: true,
        });

        setProductCustomFrequency(productUuid);
    };

    const setModified = async (productUuid: string, isModified: boolean) => {
        await productionScheduleService.patchProductForecast(productUuid, {
            is_modified: isModified,
        });

        productionScheduleHasBeenModified.value = isModified;
    };

    const setProductForecastSettingsModified = (modified: boolean) => {
        productForecastSettingsHaveBeenModified.value = modified;
    };

    const setScheduleTypeModified = (modified: boolean) => {
        scheduleTypeModified.value = modified;
    };

    const setProductionScheduleModifiedAt = (
        modifiedAt: string | null | undefined
    ) => {
        productionScheduleModifiedAt.value = modifiedAt
            ? new Date(modifiedAt)
            : undefined;
    };

    const fetchProductionSchedule = async (
        productUuid: string,
        showLoader = false
    ): Promise<ForecastMonth[] | undefined> => {
        if (showLoader) loading.value = true;

        try {
            const response =
                await productionScheduleService.getProductionScheduleForecast(
                    productUuid
                );

            const productProductionSchedule =
                response.data.results &&
                response.data.results.find(
                    (productionScheduleItem) =>
                        productionScheduleItem.product === productUuid
                );

            if (response.data.results && response.data.results[0].months) {
                productionSchedule.value = response.data.results[0].months;

                setProductionScheduleModifiedAt(
                    productProductionSchedule?.modified_at
                );
            } else {
                productionScheduleDisabled.value = true;
                productionScheduleExpanded.value = false;
            }

            if (productProductionSchedule?.is_modified !== undefined) {
                productionScheduleHasBeenModified.value =
                    productProductionSchedule?.is_modified;
            } else {
                productionScheduleHasBeenModified.value = false;
            }
        } catch (err: any) {
            handleError(err);
        }

        if (showLoader) loading.value = false;

        return productionSchedule.value;
    };

    const createProductionScheduleForecast = async (
        productUuid: string,
        showLoader = false
    ) => {
        if (showLoader) loading.value = true;

        try {
            const response =
                await productionScheduleService.createProductionScheduleForecast(
                    productUuid
                );

            productionSchedule.value = response.data.months;

            setProductionScheduleModifiedAt(response.data.modified_at);

            if (showLoader) loading.value = false;

            productionScheduleDisabled.value = false;
            productionScheduleExpanded.value = true;

            return productionSchedule.value;
        } catch (err: any) {
            handleError(err);
            loading.value = false;
            return [];
        }
    };

    const fetchOrCreateProductionSchedule = async (productUuid: string) => {
        loading.value = true;

        const productionScheduleResponse = await fetchProductionSchedule(
            productUuid,
            false
        );

        if (!productionScheduleResponse || !productionScheduleResponse.length) {
            return createProductionScheduleForecast(productUuid, false);
        }

        loading.value = false;

        return productionScheduleResponse;
    };

    const updateMonthByUUID = async (
        uuid: string,
        payload: Partial<ForecastMonth>
    ) => {
        try {
            const response =
                await productionScheduleService.editMonthForecastByUUID(
                    uuid,
                    payload
                );

            forecastMonthByUUID.value = forecastMonthByUUID.value.map((item) =>
                item.uuid === uuid ? response.data : item
            );
        } catch (err: any) {
            handleError(err);
        }
    };

    const createProductionRunForecast = async (
        payload: Partial<ProductionRun>
    ) => {
        try {
            const response =
                await productionScheduleService.createProductionRunForecast(
                    payload
                );

            const newProductionRun = response.data;

            productionRun.value = [...productionRun.value, newProductionRun];

            return newProductionRun;
        } catch (err: any) {
            handleError(err);
            return null;
        }
    };

    const editProductionRun = async (
        uuid: string,
        payload: Partial<ProductionRun>
    ) => {
        try {
            const response =
                await productionScheduleService.editProductionRunByUUID(
                    uuid,
                    payload
                );
            productionRunByUUID.value = productionRunByUUID.value.map((item) =>
                item.uuid === uuid ? response.data : item
            );
        } catch (err: any) {
            handleError(err);
        }
    };

    const removeProductionRun = async (uuid: string) => {
        try {
            await productionScheduleService.deleteProductionRunByUUID(uuid);
            productionRunByUUID.value = productionRunByUUID.value.filter(
                (item) => item.uuid !== uuid
            );
        } catch (err: any) {
            handleError(err);
        }
    };

    const addProductionRun = async (
        monthUuid: string,
        selectedMonth: ForecastMonth,
        run: Partial<ProductionRun>
    ) => {
        const newRun = {
            ...run,
            forecast_month: monthUuid,
        } as unknown as ProductionRun;

        const newProductionRun = (await createProductionRunForecast(
            newRun
        )) as ProductionRun;

        selectedMonth.production_runs.push(newProductionRun);

        return newProductionRun;
    };

    const deleteProductionRun = async (
        runUuid: string,
        selectedMonth: ForecastMonth
    ) => {
        await removeProductionRun(runUuid);

        const runIndex = selectedMonth.production_runs.findIndex(
            (run) => run.uuid === runUuid
        );

        if (runIndex !== -1) {
            selectedMonth.production_runs.splice(runIndex, 1);
        }
    };

    const updateProductionRun = (
        runUuid: string,
        selectedMonth: ForecastMonth,
        newRunDate: string
    ) => {
        if (!selectedMonth.production_runs.length) {
            selectedMonth.production_runs.push({
                uuid: runUuid,
                forecast_month: newRunDate,
            });
        }

        const editedProductionRun = selectedMonth.production_runs.find(
            (run) => run.uuid === runUuid
        );

        if (!editedProductionRun) return;

        editedProductionRun.run_date = newRunDate;
    };

    const initialiseFirstTabId = () => {
        const unwatchSelectedProducts = watch(
            () => selectedProducts.value,
            () => {
                if (!selectedProducts.value || !selectedProducts.value.length)
                    return;

                activeProductId.value = selectedProducts.value[0].uuid;

                unwatchSelectedProducts();
            }
        );
    };

    return {
        productionSchedule,
        fetchProductionSchedule,
        editProductionRun,
        removeProductionRun,
        createProductionScheduleForecast,
        createProductionRunForecast,
        fetchOrCreateProductionSchedule,
        updateMonthByUUID,
        setCustomFrequency,
        setModified,
        addProductionRun,
        deleteProductionRun,
        getCustomProductFrequency,
        updateProductionRun,
        activeProduct,
        loading,
        activeProductId,
        productionScheduleExpanded,
        productionScheduleDisabled,
        firstProductionRun,
        initialiseFirstTabId,
        productionScheduleIsGenerated,
        productionScheduleHasBeenModified,
        productForecastSettingsHaveBeenModified,
        scheduleTypeModified,
        setProductForecastSettingsModified,
        setScheduleTypeModified,
        productionScheduleModifiedAt,
    };
};
