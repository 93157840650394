import { computed, ref } from "vue";
import { defineStore } from "pinia";

import type { Notification } from "@/types/api/data-contracts";

import Notifications from "@/services/Notifications";

/**
 * A store for managing actions.
 * Actions are defined as actionable notifications exposed as desktop messages to the user.
 *
 * @module useActionsStore
 * @returns {Object} The actions store instance with properties and methods to interact with the store.
 */
export const useActionsStore = defineStore("actionsStore", () => {
    const notificationsService = Notifications.getInstance();
    /**
     * Represents the total number of actions.
     * This is not directly exposed and remains "encapsulated",
     * it can be accessed only through the computed reference `getTotalActionsCount`
     *
     * @type {Ref<number>}
     */
    const totalActions = ref(0);

    /**
     * A collection of actions object to display in the Dashboard.
     * This is currently set only in the Brand Actions.
     */
    const dashboardActions = ref<Notification[] | undefined>([]);

    const loadingActions = ref(false);

    const $reset = () => {
        totalActions.value = 0;
        dashboardActions.value = [];
        loadingActions.value = false;
    };

    const beginLoadingActions = () => {
        loadingActions.value = true;
    };

    const endLoadingActions = () => {
        loadingActions.value = false;
    };

    /**
     * Setter for the total number of actions.
     * @param {number} actionsCount - The new total count of actions.
     */
    const setTotalActions = (actionsCount: number) => {
        totalActions.value = actionsCount;
    };

    /**
     * Setter for the dashboard actions.
     * @param {Notification[]} actions
     */
    const setDashboardActions = (actions: Notification[]) => {
        dashboardActions.value = actions;
    };

    /**
     * Empties the dashboardActions array.
     */
    const clearDashboardActions = () => {
        dashboardActions.value = [];
    };

    /**
     * Initializes the component state by fetching notifications to be displayed as actions.
     * Uses the notifications service to retrieve the list of notifications and updates
     * the reactive `actions` and `totalActions` variables with the fetched data.
     */
    const initActions = async () => {
        beginLoadingActions();
        const { data: response } = await notificationsService.getNotifications(
            1,
            "actions"
        );

        dashboardActions.value = response.results;

        endLoadingActions();

        setTotalActions(response.count || 0);
    };

    /**
     * Getter for total number of actions: computes and returns the total number of actions.
     * @type {ComputedRef<number>}
     */
    const getTotalActionsCount = computed(() => totalActions);

    /**
     * Getter for dashboard actions: computes and returns the collection of actions.
     * @type {ComputedRef<Notification[]>}
     */
    const getDashboardActions = computed(() => dashboardActions);

    /**
     * Getter for loading dashboard actions, reactive reference to the loading status: are actions currently loading?
     * @type {ComputedRef<boolean>}
     */
    const getLoadingActions = computed(() => loadingActions);

    return {
        $reset,
        beginLoadingActions,
        clearDashboardActions,
        endLoadingActions,
        getDashboardActions,
        getLoadingActions,
        getTotalActionsCount,
        initActions,
        setDashboardActions,
        setTotalActions,
    };
});
