import type { AxiosPromise } from "axios";
import axiosInstance from "@/services/axiosInstance";
import type {
    FileUpload,
    FileUploadResponse,
} from "@/types/api/data-contracts";

class FilesService {
    // eslint-disable-next-line no-use-before-define
    private static instance: FilesService;

    public static getInstance(): FilesService {
        if (!FilesService.instance) {
            FilesService.instance = new FilesService();
        }
        return FilesService.instance;
    }

    /**
     * Prepares a google upload
     * @param fileUpload
     * @returns {GoogleUploadResponse}
     */
    createResumableUploadSession(
        params: FileUpload
    ): AxiosPromise<FileUploadResponse> {
        const url = "files/upload/";
        return axiosInstance.post(url, params);
    }
}

export default FilesService;
