import {
    applyRequestInterceptors,
    applyResponseErrorHandlers,
    handleInvalidToken,
    // handleRequestAbortion,
    handleUnsupportedCountry,
    sendAuthorisationHeader,
} from "@/services/axiosInterceptors";
import axios from "axios";

const baseURL = import.meta.env.VITE_API_ENDPOINT as string;
const abortControllers: AbortController[] = [];

const axiosInstance = axios.create({ baseURL });

const requestInterceptors = [sendAuthorisationHeader];

const responseErrorHandlers = [handleInvalidToken, handleUnsupportedCountry];

applyRequestInterceptors(axiosInstance, abortControllers, requestInterceptors);
applyResponseErrorHandlers(axiosInstance, responseErrorHandlers);

export const abortAllRequests = () => {
    abortControllers.forEach((controller) => controller.abort());
};

export default axiosInstance;
