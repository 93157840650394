import { useToastStore } from "@/stores/toast";

/**
 * A list of message headers we don't want to show to our end users.
 */
const excludeHeaders = ["exc module", "exc type"];

/**
 * Displays a toast message if the displayToast flag is true.
 *
 * @param {string} errorMessage - The error message to be displayed.
 * @param {string} header - The header for the toast message.
 * @param {boolean} displayToast - Flag indicating whether to show the toast.
 */
export const showToastMessage = (
    errorMessage: string,
    header: string,
    displayToast: boolean
) => {
    if (!displayToast) return;

    if (excludeHeaders.includes(header)) return;

    const toastStore = useToastStore();

    toastStore.$patch({
        severity: "error",
        summary: header,
        detail: errorMessage,
    });
};
