import router from "@/router";
import * as Sentry from "@sentry/vue";
import type { App } from "vue";

const environment = import.meta.env.VITE_CURRENT_ENV.toLowerCase();

const sentryInit = (app: App<Element>) => {
    if (import.meta.env.VITE_DISABLE_SENTRY) return;

    Sentry.init({
        environment,
        app,
        dsn: import.meta.env.VITE_SENTRY_DNS,
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost:3000",
                    /^https:\/\/https:\/\/staging-opply\.netlify.app/,
                ],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: [""],
                networkRequestHeaders: ["X-Custom-Header"],
                networkResponseHeaders: ["X-Custom-Header"],
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

export default sentryInit;
