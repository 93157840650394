import axios, { type AxiosError } from "axios";

/**
 * Wrapper around axios `isAxiosError` method.
 * Enforce type safety by applying a type guard: when returns true typescript consider
 * the variable passed as parameter of type AxiosError.
 *
 * @param {any} error
 *
 * @returns {boolean}
 */
export const isAxiosError = (error: any): error is AxiosError =>
    axios.isAxiosError(error);
