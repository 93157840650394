import { reactive, computed } from "vue";
import { uniqueId } from "lodash";

import * as actions from "@/actions";

import type {
    ActionName,
    ActionParams,
    AppAction,
    EventListener,
    EventName,
} from "@/types";

/**
 * Maps action names to corresponding action implementations.
 * It's just reference to the `actions` import, declared to handle types correctly.
 */
const actionsMap = actions as unknown as Record<ActionName, AppAction>;

/**
 * Reactive store for event names mapped to arrays of their listeners.
 */
const eventsMap: Record<string, EventListener[]> = reactive({});

/**
 * Composable for managing event-driven interactions.
 * @returns {Object} containing methods to manipulate and trigger events and actions.
 */
export const useEvents = () => {
    /**
     * Computed ref of all registered events.
     */
    const getEvents = computed(() => eventsMap);

    /**
     * Removes all listeners for a given event.
     * @param eventName - The name of the event to unregister.
     */
    const unregisterEvent = (eventName: string) => {
        delete eventsMap[eventName];
    };

    /**
     * Removes a specific action listener from an event.
     * @param eventName - The event name from which to unregister the action.
     * @param actionId - The unique identifier of the action to remove.
     */
    const unregisterAction = (eventName: string, actionId: string) => {
        eventsMap[eventName] =
            eventsMap[eventName]?.filter(
                (actionListener) => actionListener.id !== actionId
            ) || [];
    };

    /**
     * Removes a specific action listener from an event by action name.
     * @param eventName - The event name from which to unregister the action.
     * @param actionName - The name identifier of the action to remove.
     */
    const unregisterActionByName = (
        eventName: EventName,
        actionName: ActionName
    ) => {
        eventsMap[eventName] =
            eventsMap[eventName]?.filter(
                (actionListener) => actionListener.actionName !== actionName
            ) || [];
    };

    /**
     * Registers a listener for a specific event.
     * @param eventName - The name of the event to listen to.
     * @param params - Parameters including the action name, arguments, and priority.
     * @todo Add an argument to alternatively do not override actions with the same name
     *
     * @returns {Function} to unregister the added listener.
     */
    const registerListener = (
        eventName: EventName,
        { actionName, args, priority = 10 }: ActionParams
    ) => {
        // If an action with the same name is registered override it.
        unregisterActionByName(eventName, actionName);

        if (!eventsMap[eventName]) eventsMap[eventName] = [];

        const id = uniqueId();

        const unregister = () => unregisterAction(eventName, id);

        const mergedArgs = { ...args, unregister };

        const listener = { actionName, args: mergedArgs, priority, id };

        eventsMap[eventName].push(listener);
        eventsMap[eventName].sort((a, b) => a.priority - b.priority);

        return unregister;
    };

    /**
     * Registers a listener to multiple events.
     * @param eventNames - Array of event names to listen to.
     * @param params - Parameters for the action listener.
     * @returns {Object} mapping event names to unregister functions.
     */
    const registerListenerWithMultipleEvents = (
        eventNames: EventName[],
        params: ActionParams
    ) =>
        eventNames.reduce((unregisterMany, eventName: EventName) => {
            const unregisterArray = unregisterMany;

            unregisterArray[eventName] = registerListener(eventName, params);

            return unregisterArray;
        }, {} as Record<EventName, () => void>);

    /**
     * Emits an event, executing all registered listeners in order of priority.
     * @param eventName - The name of the event to emit.
     * @param args - Arguments to pass to the action listeners.
     * @param oneOff - If true, unregister action after execution.
     * @param index - Internal use for recursion, represents the current listener index.
     */
    const emitEvent = async (
        eventName: string,
        args: Record<string, unknown> = {},
        oneOff = false,
        index = 0
    ) => {
        /** All the listeners attached to the emitted event */
        const eventListeners = eventsMap[eventName];

        // We have already executed all actions
        if (!eventListeners || index >= eventListeners.length) return;

        const currentListener = eventListeners[index];

        const currentAction = actionsMap[currentListener.actionName];

        const performNextAction = await currentAction({
            ...args,
            ...currentListener.args,
        });

        // `oneOff` is a parameter which determines if the action
        // needs to be unregistered straight after being performed
        if (oneOff) unregisterAction(eventName, currentListener.id);

        if (performNextAction) {
            await emitEvent(eventName, args, oneOff, index + 1);
        }
    };

    return {
        getEvents,
        unregisterEvent,
        unregisterAction,
        registerListener,
        registerListenerWithMultipleEvents,
        emitEvent,
    };
};
