import { handleError } from "@/utils";

class WebSockets {
    // eslint-disable-next-line no-use-before-define
    private static instance: WebSockets;

    private socket: { value: WebSocket | undefined } = { value: undefined };

    public static getInstance(): WebSockets {
        if (!WebSockets.instance) {
            WebSockets.instance = new WebSockets();
        }

        return WebSockets.instance;
    }

    establishConnection() {
        const token = localStorage.getItem("userAuthToken");

        if (!token) return null;

        const WebSocketURL = import.meta.env.VITE_API_ENDPOINT.replace(
            "http",
            "ws"
        );

        this.socket.value = new WebSocket(`${WebSocketURL}/ws/?token=${token}`);

        this.socket.value.addEventListener("open", () => {
            console.log("WebSocket connection established");
        });

        this.socket.value.addEventListener("error", (event) => {
            handleError(event);
        });

        return this.socket;
    }

    getSocket() {
        return this.socket;
    }
}

export default WebSockets;
