import { computed, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import type { Ref } from "vue";
import type { Validation } from "@vuelidate/core";
import type { NonRecurringQuoteFormData, QuoteEditFormData } from "@/types";
import type { PaymentScenarioEnum } from "@/types/api/data-contracts";
import {
    getNonRecurringRules,
    nonRecurringFormInitialState,
} from "@/components/views/suppliers/quote/QuoteEdit/non-recurring/config";
import { calculateTimePeriodInDays } from "@/utils";

const state: typeof nonRecurringFormInitialState = reactive({
    ...nonRecurringFormInitialState,
});
export const useQuoteEditForm = () => {
    const rules = getNonRecurringRules(state);

    const v$ = useVuelidate(rules, state) as unknown as Ref<
        Validation<NonRecurringQuoteFormData>
    >;

    const submitted = ref(false);

    const calculateTotalPrice = () => {
        if (state.priceOption === "total")
            return state.price ? state.price.toString() : "0";

        if (state.price === null || state.quantity === null) {
            return "0";
        }

        return (state.price * state.quantity).toFixed(2).toString();
    };

    const calculateUnitPrice = () => {
        if (state.priceOption === "unit_price")
            return state.price ? state.price.toString() : "0";

        if (state.price === null || state.quantity === null) {
            return "0";
        }

        return (state.price / state.quantity).toFixed(2).toString();
    };

    const calculateQuoteValidFor = () => {
        if (state.quote_valid_for) {
            return calculateTimePeriodInDays(
                state.quote_valid_for,
                state.quote_valid_for_unit
            );
        }

        return state.quote_valid_for;
    };

    const computeAppendMessage = computed(() => {
        let message = "";
        const { priceOption, price, currency } = state;

        if (!priceOption || !price) return "";

        if (priceOption === "unit_price") {
            const totalPrice = calculateTotalPrice();
            message = `Total Price = ${currency} ${totalPrice}`;
        } else {
            const unitPrice = calculateUnitPrice();
            message = `Unit Price = ${currency} ${unitPrice}`;
        }

        return message;
    });

    const computePaymentScenario = () => {
        if (
            state.paymentTerm === "on_dispatch" ||
            state.paymentTerm === "in_advance"
        ) {
            v$.value.payment_delay.$model = 0;
            v$.value.payment_scenario.$model =
                state.paymentTerm as PaymentScenarioEnum;
        } else if (state.paymentTerm === "other") {
            v$.value.payment_scenario.$model =
                "on_dispatch" as PaymentScenarioEnum;
            v$.value.payment_delay.$model = state.paymentTermsOther;
        } else {
            v$.value.payment_delay.$model =
                state.paymentTerm !== null ? +state.paymentTerm : null;
            v$.value.payment_scenario.$model =
                "on_dispatch" as PaymentScenarioEnum;
        }
    };

    const resetState = () => {
        Object.keys(nonRecurringFormInitialState).forEach((key) => {
            // @ts-ignore
            delete state[key];
        });
    };

    const isFieldInvalid = (
        fieldName: keyof QuoteEditFormData | keyof QuoteEditFormData
    ) => v$.value[fieldName].$invalid && submitted.value;

    return {
        calculateQuoteValidFor,
        state,
        rules,
        v$,
        submitted,
        computeAppendMessage,
        calculateTotalPrice,
        calculateUnitPrice,
        resetState,
        computePaymentScenario,
        isFieldInvalid,
    };
};
