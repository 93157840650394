/**
 * Represents an application-specific error.
 * Extends the built-in Error class.
 */
export class AppError extends Error {
    /**
     * Determines if the notification should be displayed to the end user.
     * @type {boolean}
     */
    public displayNotification: boolean;

    /**
     * Error type name for reference.
     * @private
     */
    private type: string;

    /**
     * Creates an instance of AppError.
     * @param {string} message - The error message.
     * @param {string} name - The name of the error.
     * @param {boolean} [displayNotification=false] - Indicates if the error should be displayed to the end user.
     */
    constructor(
        message: string,
        name: string,
        displayNotification: boolean = false
    ) {
        super(message);
        this.displayNotification = displayNotification;
        this.name = name;
        this.type = "AppError";
    }

    /**
     * Getter for the type property.
     */
    public getErrorType(): string {
        return this.type;
    }
}
