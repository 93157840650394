import axiosInstance from "@/services/axiosInstance";
import { axiosParamsSerialiser } from "@/services/axiosParamsSerialiser";
import type { AxiosPromise, AxiosResponse } from "axios";
import type {
    VatInvoiceResponse,
    PaginatedOrderList,
    PurchaseOrder,
    PaymentStatusEnum,
    ShipmentStatusEnum,
    OrderState,
    OrderDetail,
    OrderConfirmDispatched,
} from "@/types/api/data-contracts";

class OrderService {
    // eslint-disable-next-line no-use-before-define
    private static instance: OrderService;

    public static getInstance(): OrderService {
        if (!OrderService.instance) {
            OrderService.instance = new OrderService();
        }
        return OrderService.instance;
    }

    createOrder(payload: object) {
        return axiosInstance.post("/orders/brand/", payload);
    }

    listBrandOrders(
        /* eslint-disable camelcase */
        filter?: {
            category?: string | string[];
            shipment_status?: string | null;
            payment_status?: string | null;
            search_filter?: string | null | undefined;
            order_type?: string | null;
            page?: number | undefined;
            is_active: boolean;
        },
        /* eslint-enable camelcase */
        ordering?: string | null
    ): AxiosPromise<PaginatedOrderList> {
        return axiosInstance.get("/orders/brand/", {
            params: {
                ...filter,
                ordering,
            },
            paramsSerializer: axiosParamsSerialiser,
        });
    }

    listSupplierOrders(
        /* eslint-disable camelcase */
        filter?: {
            shipment_status?: string | null;
            payment_status?: string | null;
            search_filter: string | null | undefined;
            page: number | undefined;
        },
        /* eslint-enable camelcase */
        ordering?: string | null
    ) {
        return axiosInstance.get("/orders/supplier/", {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    brandOrderDetails(orderId: string) {
        return axiosInstance.get(`orders/brand/${orderId}/`);
    }

    deleteBrandSaleOrder(orderId: string): AxiosPromise<void> {
        return axiosInstance.delete(`orders/brand/${orderId}/`);
    }

    supplierPurchaseOrder(orderId: string): AxiosPromise<PurchaseOrder> {
        return axiosInstance.get(`orders/supplier/${orderId}/purchase-order/`);
    }

    supplierOrderDetails(orderId: string) {
        return axiosInstance.get(`orders/supplier/${orderId}/`);
    }

    uploadSupplierOrderDocument(orderId: string, formData: FormData) {
        return axiosInstance.post(
            `orders/supplier/${orderId}/documents/`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    }

    deleteSupplierOrderDocument(orderId: string, documentId: string) {
        return axiosInstance.delete(
            `orders/supplier/${orderId}/documents/${documentId}`
        );
    }

    confirmDispatch(
        orderId: string,
        payload: OrderConfirmDispatched
    ): AxiosPromise<void> {
        return axiosInstance.post(
            `orders/supplier/${orderId}/confirm-dispatch/`,
            payload
        );
    }

    brandOrderInvoice(orderId: string) {
        return axiosInstance.get(`orders/brand/${orderId}/invoice/`);
    }

    brandOrderInvoicePdf(orderId: string) {
        return axiosInstance.get(`orders/brand/${orderId}/invoice/pdf/`, {
            responseType: "blob",
        });
    }

    brandOfflineOrderInvoicePdf(orderId: string) {
        return axiosInstance.get(`orders/brand/${orderId}/offline-order/pdf/`, {
            responseType: "blob",
        });
    }

    getSupplierOrderVatInvoicePdf(orderId: string) {
        return axiosInstance.get(`orders/supplier/${orderId}/vat-invoice/pdf/`);
    }

    // Opply commission PDF
    getBrandOrderVatInvoicePdf(
        orderId: string
    ): AxiosPromise<VatInvoiceResponse> {
        return axiosInstance.get(`orders/brand/${orderId}/vat-invoice/pdf/`);
    }

    // Order invoice for brands too
    listSupplierOrderDocuments(orderId: string) {
        return axiosInstance.get(`/orders/supplier/${orderId}/documents/`);
    }

    supplierOrderConfirmationPdf(orderId: string) {
        return axiosInstance.get(
            `/orders/supplier/${orderId}/purchase-order/pdf/`
        );
    }

    confirmOrderDelivery(
        orderId: string,
        payload: any
    ): AxiosPromise<OrderDetail> {
        return axiosInstance.post(
            `/orders/brand/${orderId}/confirm-delivered/`,
            payload
        );
    }

    orderPaymentStatusUpdate = (
        orderId: string,
        status: `${PaymentStatusEnum}`
    ) =>
        axiosInstance.patch(`/orders/brand/${orderId}/partial-update/`, {
            payment_status: status,
        });

    orderShipmentStatusUpdate = (
        orderId: string,
        status: `${ShipmentStatusEnum}`
    ) =>
        axiosInstance.patch(`/orders/brand/${orderId}/partial-update/`, {
            shipment_status: status,
        });

    orderGeneralStatusUpdate = (orderId: string, status: `${OrderState}`) =>
        axiosInstance.patch(`/orders/brand/${orderId}/partial-update/`, {
            state: status,
        });

    // eslint-disable-next-line camelcase
    fetchBasketVolumes(quote_uuids: string[]) {
        // eslint-disable-next-line camelcase
        const body = { quote_uuids };
        return axiosInstance.post("/orders/brand/basket-volumes/", body);
    }

    createOfflineOrder(payload: object) {
        return axiosInstance.post(
            "/orders/brand/create-offline-order/",
            payload
        );
    }

    getOfflineOrderPdf(orderId: string): Promise<AxiosResponse<File>> {
        return axiosInstance.get(`orders/brand/${orderId}/offline-order/pdf/`, {
            responseType: "blob",
        });
    }

    downloadGoodReceipt(orderId: string): AxiosPromise {
        const url = `/orders/brand/${orderId}/goods-receipt/pdf/`;
        return axiosInstance.get(url);
    }

    createDispute(orderId: string, payload: object): AxiosPromise {
        const url = `/orders/brand/${orderId}/disputes/`;
        return axiosInstance.post(url, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    getDisputes(orderId: string): AxiosPromise {
        const url = `/orders/brand/${orderId}/disputes/`;
        return axiosInstance.get(url);
    }
}

export default OrderService;
