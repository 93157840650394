import { CompanyTypeEnum } from "@/types/api/data-contracts";

/**
 * Determines if a given route path is valid based on the provided brand and supplier identifiers.
 *
 * @param {string} routePath - The route path to validate.
 * @param {CompanyTypeEnum} userType - Company type of current user
 *
 * @returns {boolean} Returns:
 *   - true if the route starts with '/brands' and current user is brand
 *   - true if the route starts with '/suppliers' and current user is supplier
 *   - true if the route doesn't start with either '/brands' or '/suppliers'
 *   - false otherwise
 *
 * @example
 * // Returns true
 * isValidRoute('/brands/something', isBrand)
 *
 * @example
 * // Returns false
 * isValidRoute('/brands/something', isSupplier)
 *
 * @example
 * // Returns true
 * isValidRoute('/common/route', anything)
 */
export const isValidRoute = (
    routePath: string,
    userType: CompanyTypeEnum
): boolean => {
    if (routePath.startsWith("/brands")) {
        return userType === CompanyTypeEnum.Brand;
    }
    if (routePath.startsWith("/suppliers")) {
        return userType === CompanyTypeEnum.Supplier;
    }
    return true;
};
