import type { Store } from "pinia";

/**
 * we call the $reset function if the store has $reset function and if
 * the store id is not useToastStore as if we reset useToastStore then
 * an empty toast is created.
 *
 * We show a warning console on staging and dev env if a store does not
 * have a $reset function
 *
 * @param store
 */
export const safeResetStore = (store: Store) => {
    if (typeof store.$reset === "function" && store.$id !== "useToastStore") {
        store.$reset();
    } else if (import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION") {
        console.warn(
            `Store "${store.$id}" doesn't have a $reset method. Please add one!`
        );
    }
};
