export const vPopperThemes = {
    "accent-tip": {
        $extend: "tooltip",
    },
    "pink-tip": {
        $extend: "tooltip",
    },
    "purple-tip": {
        $extend: "tooltip",
    },
    "pink-tip-2": {
        $extend: "tooltip",
    },
    "yellow-tip": {
        $extend: "tooltip",
    },
    "alert-tip": {
        $extend: "tooltip",
    },
    "opply-ai-tip": {
        $extend: "tooltip",
    },
    "sidebar-tip": {
        $extend: "tooltip",
    },
};
