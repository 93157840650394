import { AppError } from "@/types/error-handling/AppError";
import {
    handleAppError,
    handleAxiosError,
    handleError,
    handleUnknownError,
    isAxiosError,
} from "@/utils";

/**
 * Handles errors when the exception is an object:
 * - if it's an AppError handles the response accordingly
 * - if it's an Axios Error handles the response accordingly
 * - if it's an Error instance, it's a JavaScript error, and we will hide from user
 * - otherwise iterates over object properties where each iteration will trigger an error handling.
 *
 *
 * @param {any} error - The error object to handle.
 * @param {boolean} displayToast - Flag to determine if the error should be displayed to the user.
 */
export const handleObjectError = (error: any, displayToast: boolean) => {
    if (error instanceof AppError && error.getErrorType() === "AppError") {
        handleAppError(error);
    } else if (isAxiosError(error)) {
        handleAxiosError(error, displayToast);
    } else if (error instanceof Error) {
        // It's a JavaScript error, we only log it, but we hide from the user.
        handleUnknownError(error, displayToast);
    } else {
        Object.entries(error).forEach(([key, value]) => {
            const title = key.replaceAll("_", " ");
            handleError(value, displayToast, title);
        });
    }
};
