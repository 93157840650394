import * as Sentry from "@sentry/vue";
import formatDate from "@/utils/formatDate";
import type { Message, NullEnum } from "@/types/api/data-contracts";
import type { ChannelObjectHistoryExtended, RollupMessage } from "@/types";
import { upperFirst } from "lodash";

/**
 * List of actions that should not be used for grouping messages.
 */
const purchaseRequestTypeActionsToIgnore: (
    | string
    | NullEnum
    | null
    | undefined
)[] = ["purchase_request_created", "purchase_request_quote_created"];

// const purchaseRequestTypesToIgnore = ["quote", "ignore"];

/**
 * Given a type and an action determines which object name to display.
 * in the chat title.
 * @param type
 * @param action
 */
export const determineDisplayObjectName = (
    type: string,
    action: string | null | NullEnum | undefined
) => {
    const contractActionTypes: (string | null | NullEnum | undefined)[] = [
        "contract_created",
        "purchase_request_order_created",
    ];
    if (contractActionTypes.includes(action)) return "Contract";
    if (action === "request_created") return "Request";
    if (action === "quote_created") return "Quote";
    return upperFirst(type);
};

export const buildGroupingKey = (historyItem: ChannelObjectHistoryExtended) => {
    const {
        object_type: objectType,
        action,
        created: historyCreationDate,
        data,
    } = historyItem;

    /**
     * Display object name differs from the object_type
     * as the object type could be "inquiry" for a contract.
     */
    const displayObjectName = determineDisplayObjectName(objectType, action);

    const formattedDate = formatDate(historyCreationDate);

    let groupingKey = "";

    if (action === "purchase_request_order_created") {
        const quantity = data?.order_quantity;
        const unit = data?.order_unit;
        const quantityAndUnit = quantity && unit ? `${quantity}${unit}` : "";

        if (!quantityAndUnit) {
            Sentry.captureException(
                "Message history item: purchase_request_order_created without quantity or unit."
            );
            groupingKey = `Ordered on ${formattedDate}`;
        } else {
            groupingKey = `${quantityAndUnit} ordered on ${formattedDate}`;
        }
    } else if (action === "ignore") {
        groupingKey = "ignore";
    } else {
        groupingKey = `${displayObjectName} created on ${formattedDate}`;
    }

    return groupingKey;
};

export const groupMessagesByChannelHistory = (
    messages: Message[],
    history: ChannelObjectHistoryExtended[],
    canFetchMoreMessages: boolean
): Record<string, (Message | RollupMessage)[]> => {
    const groupedMessages: Record<string, (Message | RollupMessage)[]> = {};

    // Dummy history item for quote/orders created
    // before history was implemented
    const updatedHistory = [
        ...(history || []),
        {
            object_type: "quote",
            object_uuid: "",
            created: "2020-01-01T12:39:37+0000",
            action: "ignore",
            data: {},
        } as unknown as ChannelObjectHistoryExtended,
    ];

    let previousMessageCreationDate = "";

    messages.forEach((message) => {
        const { created: messageCreationDate } = message;

        updatedHistory.some((historyItem) => {
            if (purchaseRequestTypeActionsToIgnore.includes(historyItem.action))
                return false; // continue

            if (historyItem.created > messageCreationDate) return false; // continue

            if (!groupedMessages[historyItem.created])
                groupedMessages[historyItem.created] = [];

            if (
                (previousMessageCreationDate || !canFetchMoreMessages) &&
                historyItem.created > previousMessageCreationDate
            ) {
                groupedMessages[historyItem.created].push({
                    text: buildGroupingKey(historyItem),
                    sender: "rollup_message",
                });
            }

            groupedMessages[historyItem.created].push(message);

            return true; // break
        });

        previousMessageCreationDate = messageCreationDate;
    });

    if (!Object.values(groupedMessages).length) {
        groupedMessages.ignore = messages;
    }

    return groupedMessages;
};
