import {
    type QuoteDescriptionBlockCreate,
    SelectedPriceOptionEnum,
} from "@/types/api/data-contracts";
import { TimePeriodEnum } from "@/types/TimePeriod";
import { INQUIRY_ICON_FALLBACK } from "@/constants";

export const recurringQuoteInitialData = {
    autocomplete: false,
    product_name: null as unknown as string,
    logo_url: INQUIRY_ICON_FALLBACK,
    sku: null as unknown as string,
    packaging_format: null as unknown as string,
    vat_rate: null as unknown as number,
    delivery_date: null as unknown as string,
    lead_time: null as unknown as number,
    recurring_order_lead_time: null as unknown as number,
    quote_valid_for: null as unknown as number,
    quote_valid_for_unit: TimePeriodEnum.Days,
    product_description: null as unknown as string,
    is_custom_contract: false,
    quantity: null as unknown as number,
    minimum_order_quantity: null as unknown as number,
    quantity_per_order: null as unknown as number,
    moqOptions: null as unknown as string,
    case_size: null as unknown as number,
    contract_length: null as unknown as number,
    unit_of_measurement: "",
    unit: "", // Don't use,
    currency: null as unknown as string,
    unit_price: null as unknown as number,
    total_price: null as unknown as number,
    shipping_cost: null as unknown as number,
    shipping_vat_rate: null as unknown as number,
    incoterms: null as unknown as string,
    dispatch_address: null as unknown as number,
    show_to_other_buyers: false,
    order_in_multiples_of: null as unknown as number,
    minimum_autoconfirm_order_quantity: null as unknown as number,
    maximum_autoconfirm_order_quantity: null as unknown as number,
    description_blocks: [] as QuoteDescriptionBlockCreate[],
    total_forecast_quantity: undefined as unknown as number,
    selected_price_option: SelectedPriceOptionEnum.Unit,
};

export type RecurringQuoteInitialData = typeof recurringQuoteInitialData;
