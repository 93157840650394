const isNullish = (obj: Object) =>
    Object.values(obj).every((value) => value === null || value === "");

export const isPartialNull = (obj: Object, nullishArr: string[] = []) =>
    Object.entries(obj).some(([key, value]) => {
        if (nullishArr.includes(key)) {
            return value === null || value === "";
        }
        return value === null || value === "" || value === 0;
    });

export default isNullish;
