import axiosInstance from "@/services/axiosInstance";
import type { hostedOnboardingPayload } from "@/types/KybPayout";
import type { MorKYBDetails } from "@/types/api/data-contracts";
import type { AxiosPromise } from "axios";

class KybService {
    // eslint-disable-next-line no-use-before-define
    private static instance: KybService;

    public static getInstance(): KybService {
        if (!KybService.instance) {
            KybService.instance = new KybService();
        }
        return KybService.instance;
    }

    getMorKybStatus(): AxiosPromise<MorKYBDetails> {
        const url = "/companies/brands/mor-kyb-status/";
        return axiosInstance.get(url);
    }

    onBoard(payload: object) {
        return axiosInstance.post("rapyd/kyb/onboarding/", payload);
    }

    getCompanyDetails() {
        return axiosInstance.get("/rapyd/kyb/onboarding/");
    }

    getAuthorisedUser() {
        return axiosInstance.get("/rapyd/kyb/authorised/");
    }

    addAuthorisedUser(payload: object) {
        return axiosInstance.post("/rapyd/kyb/authorised/", payload);
    }

    addShareholders(payload: object) {
        return axiosInstance.post("/rapyd/kyb/shareholders/", payload);
    }

    getStatus() {
        return axiosInstance.get("/rapyd/check_kyb/");
    }

    getBeneficiaryId() {
        return axiosInstance.post("/rapyd/payout/get_redirect_id/");
    }

    payoutCallback(payload: object) {
        return axiosInstance.post("/rapyd/payout/callback/", payload);
    }

    deleteDocuments(supplierId: string, fileId: string) {
        return axiosInstance.delete(
            `/companies/suppliers/${supplierId}/documents/${fileId}/`
        );
    }

    hostedVersionOnboarding(payload: hostedOnboardingPayload) {
        const url = "/rapyd/kyb/step1/";
        return axiosInstance.post(url, payload);
    }
}

export default KybService;
