import { defineStore } from "pinia";
import KYBService from "@/services/Kyb";
import RapydService from "@/services/Rapyd";
import handleError from "@/utils/handleError";
import type { kybStatusCheck, payoutStatusCheck } from "@/types/KybPayout";

// constants
const KybService = KYBService.getInstance();
const rapydService = RapydService.getInstance();

export const useSupplierStore = defineStore({
    id: "supplierStore",
    state: () => ({
        kyb: null as unknown as kybStatusCheck,
        payout: null as unknown as payoutStatusCheck,
    }),
    getters: {
        kybStatus: (state) => state?.kyb?.status ?? "Loading",
        payoutStatus: (state) => state?.payout?.status ?? "Loading",
        isPayoutComplete: (state) => state?.payout?.status === "Completed",
        isKybComplete: (state) => state?.kyb?.status === "Completed",
    },
    actions: {
        async fetchKybStatus() {
            try {
                const res = await KybService.getStatus();
                this.kyb = await res.data;
            } catch (err: any) {
                const errors = err?.response?.data;
                handleError(errors);
            }
        },
        async fetchPayoutStatus() {
            try {
                const res = await rapydService.getPayoutStatus();
                this.payout = await res.data;
            } catch (err: any) {
                const errors = err?.response?.data;
                handleError(errors);
            }
        },
        fetchSupplierKybPayoutDetails() {
            this.fetchPayoutStatus();
        },
    },
});

export default { useSupplierStore };
