import {
    AllergenRequirementsEnum,
    DietRequirementsEnum,
    FrequencyEnum,
    UnitOfMeasurementEnum,
} from "@/types/api/data-contracts";

import { OPPLY_SQUARE_LOGO_ICON } from "@/constants";
import type IDocument from "@/types/Document";

export interface IInitialData {
    name: string;
    due_date?: string;
    quantity: number | null;
    pricing_option: string;
    current_price: number | null;
    target_price: number | null;
    unit_of_measurement: UnitOfMeasurementEnum | "";
    description: string;
    desired_packaging_format: string;
    desired_packaging_format_is_flexible: boolean;
    current_supplier: string;
    supplier_countries?: string;
    currency: string;
    logo_url: string;
    existing_suppliers: string[];
    allergen_requirements: AllergenRequirementsEnum[];
    allergen_declaration: null | boolean;
    diet_requirements: DietRequirementsEnum[];
    dietary_certificate: null | boolean;
    forecast: null | string;
    is_recurring: boolean;
    frequency?: FrequencyEnum;
    desired_contract_length: number | null;
    first_order_delivery_date: string;
    is_delivery_date_flexible?: boolean;
    newIngredient?: boolean;
    ingredient?: number | null;
    documents: IDocument[];
    documents_to_copy: IDocument[];
}

interface IArrIngredient {
    collapse: boolean;
    files: File[];
    uploadedFiles: IDocument[];
    uploadedIngredientFiles: IDocument[];
    formData: IInitialData;
    isFindNewSuppliers: boolean;
    receiveFromNewSuppliers: boolean;
    reason_for_request?: string;
}

export interface IState {
    count: number;
    arrIngredient: IArrIngredient[];
}

export const formInitialData: IInitialData = {
    name: "",
    pricing_option: "",
    current_price: null,
    target_price: null,
    currency: "GBP",
    description: "",
    desired_packaging_format: "",
    desired_packaging_format_is_flexible: false,
    quantity: null,
    unit_of_measurement: "",
    allergen_requirements: [],
    allergen_declaration: false,
    diet_requirements: [],
    dietary_certificate: false,
    forecast: null,
    current_supplier: "",
    logo_url: OPPLY_SQUARE_LOGO_ICON,
    existing_suppliers: [],
    frequency: FrequencyEnum.Weekly,
    is_recurring: false,
    desired_contract_length: null,
    first_order_delivery_date: "",
    due_date: "",
    is_delivery_date_flexible: false,
    newIngredient: true,
    documents: [],
    documents_to_copy: [],
};
