import type { AxiosError } from "axios";

import { handleError, handleUnknownError } from "@/utils";

export const handleAxiosError = (error: AxiosError, displayToast: boolean) => {
    if (error.code === "500") {
        handleUnknownError(error);
    } else if (error.response?.data) {
        handleError(error.response.data, displayToast);
    } else {
        handleUnknownError(error);
    }
};
