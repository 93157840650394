import { useEvents } from "@/composables";
import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type {
    PaginatedArticleList,
    ProductInventory,
    Article,
    PaginatedIngredientList,
    IngredientsForProductList,
} from "@/types/api/data-contracts";

class BrandProductService {
    // eslint-disable-next-line no-use-before-define
    private static instance: BrandProductService;

    public static getInstance(): BrandProductService {
        if (!BrandProductService.instance) {
            BrandProductService.instance = new BrandProductService();
        }
        return BrandProductService.instance;
    }

    createProduct(payload: Partial<Article>): AxiosPromise<Article> {
        const url = `/inquiries/articles/`;
        const createProductPromise = axiosInstance.post(url, payload);

        useEvents().emitEvent("createProductRequested", {
            createProductPromise,
        });

        return createProductPromise;
    }

    getListings(
        filter?: {
            autocomplete_filter?: string | null;
            page?: number | undefined;
        },
        ordering?: string | null
    ): AxiosPromise<PaginatedArticleList> {
        return axiosInstance.get("/inquiries/articles/retrieve_products/", {
            params: {
                ...filter,
                ordering,
            },
        });
    }

    deleteProduct(uuid: string): AxiosPromise {
        const url = `inquiries/articles/${uuid}/`;
        return axiosInstance.delete(url);
    }

    getProduct(uuid: string): AxiosPromise<Article> {
        const url = `inquiries/articles/${uuid}/`;
        return axiosInstance.get(url);
    }

    editProduct(
        uuid: string,
        payload: Partial<Article>
    ): AxiosPromise<Article> {
        const url = `inquiries/articles/${uuid}/`;
        return axiosInstance.put(url, payload);
    }

    assignIngredient(
        uuid: string,
        payload: IngredientsForProductList[]
    ): AxiosPromise {
        const url = `inquiries/articles/${uuid}/assign_ingredients/`;
        return axiosInstance.post(url, { ingredients: payload });
    }

    deleteProductIngredient(
        productUuid: string,
        uuids: string[]
    ): AxiosPromise<PaginatedIngredientList> {
        const url = `inquiries/articles/${productUuid}/remove_ingredients/`;
        return axiosInstance.post(url, {
            uuids,
        });
    }

    getProductIngredient(
        uuid: string,
        filter?: {
            page?: number | undefined;
        }
    ): AxiosPromise<PaginatedIngredientList> {
        const url = `inquiries/articles/${uuid}/retrieve_ingredients/`;

        return axiosInstance.get(url, {
            params: {
                ...filter,
            },
        });
    }

    createProductInventory(
        productUuid: string,
        payload: object
    ): AxiosPromise<ProductInventory> {
        const url = `/inventory/product/`;
        return axiosInstance.post(url, { ...payload, product: productUuid });
    }

    updateInventoryProduct(
        uuid: string,
        payload: object
    ): AxiosPromise<PaginatedIngredientList> {
        const url = `/inventory/product/${uuid}/`;
        return axiosInstance.patch(url, payload);
    }

    assignProductInventory(
        inventory: ProductInventory
    ): AxiosPromise<ProductInventory> {
        const url = "/inventory/product/";
        return axiosInstance.post(url, inventory);
    }

    updateProductInventory(
        inventory: ProductInventory,
        inventoryId: string
    ): AxiosPromise<ProductInventory> {
        const url = `/inventory/product/${inventoryId}/`;
        return axiosInstance.put(url, inventory);
    }

    markProductInventoryAsComplete(
        productId: string,
        quantity: number
    ): AxiosPromise<void> {
        const url = `/inventory/product/${productId}/production_complete/`;
        return axiosInstance.post(url, { quantity_complete: quantity });
    }

    addProductToProduction(inventoryId: string, quantityToAllocate: number) {
        const url = `/inventory/product/${inventoryId}/allocate/`;

        return axiosInstance.post(url, {
            quantity_to_allocate: quantityToAllocate,
        });
    }
}

export default BrandProductService;
