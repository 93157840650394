import * as Sentry from "@sentry/vue";

import { AppError } from "@/types/error-handling/AppError";
import { buildErrorObject, buildUserContext } from "@/utils";

/**
 * Captures an exception using Sentry, including user context and additional info.
 * @param {any} exception - The exception to be captured.
 * @param {string} [header] - Optional header to be used as the error title.
 */
export const sentryCaptureException = (exception: any, header?: string) => {
    let exceptionAsError: Error | null = null;

    if (exception instanceof AppError) {
        exceptionAsError = exception;
    } else {
        exceptionAsError = buildErrorObject(exception, header);
    }

    const userContext = buildUserContext();

    Sentry.setContext("user", userContext);

    Sentry.captureException(exceptionAsError, {
        level: "error",
        contexts: {
            user: userContext,
        },
        extra: {
            originalException: exception,
        },
    });
};
