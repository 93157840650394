import axiosInstance from "@/services/axiosInstance";
import type { AxiosPromise } from "axios";
import type { CompanyDetailsRetrieve } from "@/types/api/data-contracts";

class MarketFinanceService {
    // eslint-disable-next-line no-use-before-define
    private static instance: MarketFinanceService;

    public static getInstance(): MarketFinanceService {
        if (!MarketFinanceService.instance) {
            MarketFinanceService.instance = new MarketFinanceService();
        }
        return MarketFinanceService.instance;
    }

    createKriyaOrder(quotes: string[], paymentMethod: string) {
        const body = { quotes, payment_method: paymentMethod };

        return axiosInstance.post("/kriya/create-order/", body);
    }

    updateCompanyDetails(payload: object) {
        return axiosInstance.put("/kriya/kyb/company-details/", payload);
    }

    getCompanyDetails(): AxiosPromise<CompanyDetailsRetrieve> {
        return axiosInstance.get("/kriya/kyb/company-details/");
    }

    uploadDirectors(payload: object) {
        return axiosInstance.post("/kriya/kyb/directors/", payload);
    }

    updateDirector(payload: object, id: string) {
        return axiosInstance.put(`/kriya/kyb/directors/${id}/`, payload);
    }

    getDirectors() {
        return axiosInstance.get("/kriya/kyb/directors/");
    }

    deleteDirector(id: string) {
        return axiosInstance.delete(`/kriya/kyb/directors/${id}/`);
    }

    uploadShareholders(payload: object) {
        return axiosInstance.post("/kriya/kyb/shareholders/", payload);
    }

    getShareholders() {
        return axiosInstance.get("/kriya/kyb/shareholders/");
    }

    updateShareholder(payload: object, id: string) {
        return axiosInstance.put(`/kriya/kyb/shareholders/${id}/`, payload);
    }

    deleteShareholders(id: string) {
        return axiosInstance.delete(`/kriya/kyb/shareholders/${id}/`);
    }

    submitKyb() {
        return axiosInstance.post("/kriya/kyb/submit-kyb/");
    }

    getBuyerPricingScheme() {
        return axiosInstance.get("/kriya/pricing-scheme/");
    }
}

export default MarketFinanceService;
